/**
 * 路由切换工具类
 *
 *  2021-06-15  wuzhipeng
 */

import { setCache, getCache } from '@/utils/cacheUtil'
import cacheKeyConst from '@/const/cacheKeyConst'

//切换路由  reload是否重新加载页面，默认false 不重新加载
export function pushRoute(route, reload) {
  let _class = Object.prototype.toString.call(route)
  let path = route
  if (_class === '[object Object]') {
    path = route.path
    //缓存路由传递的params参数
    setCache(cacheKeyConst.ROUTE_PARAMS, route.params)
  } else {
    //清理旧的参数
    setCache(cacheKeyConst.ROUTE_PARAMS, '')
  }
  if (window.$router) {
    window.$router.push(path, reload)
  } else if (window.parent.$router) {
    window.parent.$router.push(path, reload)
  } else {
    console.log('路由切换失败，未加载主框架')
  }

}

//根据path关闭页面
export function closePage(path) {
  if (window.$router) {
    window.$router.closePage(path)
  } else if (window.parent.$router) {
    window.parent.$router.closePage(path)
  } else {
    alert('失败，未加载主框架')
  }
}

//获取路由传递的参数,只能获取一次，获取后就删除了
export function getRouteParams() {
  let params = getCache(cacheKeyConst.ROUTE_PARAMS)
  //清理旧的参数
  setCache(cacheKeyConst.ROUTE_PARAMS, '')
  return params
}

//获取url传递的参数
export function getUrlParam(paramName) {
  //构造一个含有目标参数的正则表达式对象
  let reg = new RegExp('(^|&)' + paramName + '=([^&]*)(&|$)')
  //匹配目标参数
  let r
  let url
  if (window.$router) {
    //框架调用
    url = window.location.href
    //r = window.location.href.substr(1).match(reg);
  } else if (window.parent.$router) {
    //框架内部子页面调用
    url = window.parent.location.href
    //r = window.parent.location.href.substr(1).match(reg);
  } else {
    url = window.location.href
    //r = window.location.href.substr(1).match(reg);
  }
  let search = url.substr(url.indexOf('?')+1)
  r = search.match(reg)
  if (r != null) {
    return decodeURIComponent(r[2])
  }

  return null
}



