<template>
  <div class="Column_Settings">
    <el-dialog
      :visible.sync="dialogSetting"
      title="显示列设置"
      width="50%"
      center
      class="vertical-middle-dialog">
      <el-table
        ref="settingTable"
        :data="tableData"
        :cell-style="{textAlign: 'center', padding: '5px 0'}"
        :header-cell-style="{textAlign: 'center'}"
        tooltip-effect="dark"
        style="width: 100%"
        :height="400"
        stripe
        border
        highlight-current-row
        @row-click="tableRowClick">
        <el-table-column
          label="序号"
          type="index"
          width="55"/>
        <el-table-column
          label="列名称"
          prop="name"
          show-overflow-tooltip
          width=""/>
        <el-table-column
          prop=""
          label="显示"
          width="55">
          <template slot-scope="scope">
            <el-checkbox v-model="scope.row.show"/>
          </template>
        </el-table-column>
<!--        <el-table-column
          prop=""
          label="排序"
          width="55">
          <template slot-scope="scope">
            <el-checkbox v-model="scope.row.sortable"/>
          </template>
        </el-table-column>-->
        <el-table-column
          prop=""
          label="对齐方式"
          width="140">
          <template slot-scope="scope">
            <el-select
              v-model="scope.row.align"
              size="small"
              placeholder="请选择"
              style="width:85px;">
              <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.label"
                :value="item.value"/>
            </el-select>
          </template>
        </el-table-column>
<!--        <el-table-column-->
<!--          prop=""-->
<!--          label="宽度"-->
<!--          width="90">-->
<!--          <template slot-scope="scope">-->
<!--            <el-input-->
<!--              v-model="scope.row.width"-->
<!--              size="small"-->
<!--              style="width:60px;"/>-->
<!--          </template>-->
<!--        </el-table-column>-->
      </el-table>
      <p>
        冻结序号
        <span><el-input
          v-model="fixedNum"
          size="small"
          style="width: 50px"
          /></span>
<!--        @keyup.enter.native="numKeyUp"-->
        及之前所有列（包括不显示列在内）
      </p>
      <div class="direction_btns clearfix">
        <!-- // 升至最上方 -->
        <div @click="top()">
          <!-- <i class="iconfont">&#xe635;</i> -->
          <i class="el-icon-upload2"></i>
        </div>
        <!-- // 往上升一级 -->
        <div @click="previous()">
          <!-- <i class="iconfont">&#xe607;</i> -->
          <i class="el-icon-top"></i>
        </div>
        <!-- // 往下降一级 -->
        <div @click="next()">
          <!-- <i class="iconfont">&#xe600;</i> -->
          <i class="el-icon-bottom"></i>
        </div>
        <!-- // 降至最底部 -->
        <div @click="bottom()">
          <!-- <i class="iconfont">&#xe634;</i> -->
          <i class="el-icon-download"></i>
        </div>
      </div>
      <div slot="footer" class="setting_btns">
        <div class="func_btns clearfix">
          <el-button
            :disabled="disabled"
            size="small"
            type="primary"
            @click="confirm()">确定</el-button>
          <el-button
            size="small"
            @click="cancel()">取消</el-button>
          <el-button
            size="small"
            @click="defaultSetting()">默认设置</el-button>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { UCsaveOrUpdate, UCgetConfig } from '@/utils/columnSettings'

export default {
  props: {
    configKey: String,
    configClassify: String,
    // updateId: String,
    getHeader: Function,
    defaultList: Array
  },
  data() {
    return {
      maxHeight: 0,
      minHeight: 0,
      dialogSetting: false,
      disabled: false,
      fixedNum: 1,
      tableData: [],
      tableDataShow: [],
      options: [
        {
          label: '居中',
          value: 'center'
        },
        {
          label: '左对齐',
          value: 'left'
        },
        {
          label: '右对齐',
          value: 'right'
        }
      ]
    }
  },
  deactivated() {
    this.$destroy('ColumnSettings')
  },
  watch: {
    // 'fixedNum': {
    //   deep: true,
    //   handler(newVal, oldVal) {
    //     // if (newVal.trim().length !== 0) {
    //     //this.getList是methods中延迟后调用的方法 延迟500ms
    //     this.debounce(this.numKeyUp, 500)
    //     // }
    //   }
    // }
  },
  methods: {
    //防抖
    debounce(fn, wait) {
      if (this.timer !== null) {
        clearTimeout(this.timer)
      }
      this.timer = setTimeout(fn, wait)
    },
    // 固定列数的keyUp事件，控制“确定”按钮能否点击
    numKeyUp() {
      this.disabled = false
      if (
        !/^[0-9]+\d*$/.test(this.fixedNum) ||
        this.fixedNum - this.tableData[this.tableData.length - 1].rank > 0
      ) {
        this.$message({
          type: 'warning',
          duration: 1500,
          message: '请输入正确的序号！'
        })
        this.disabled = true
      }
    },
    tableRowClick(row, event, column) {
      // console.log(row, event, column)
      this.tableRank = row.rank
      this.selectedRow = row
    },
    // 交换数组中的任意两个数据的位置
    swapArray(arr, index1, index2) {
      // arr：需要改变的原数组；index1,index2：交换的索引值，splice返回的是被修改的数据组成的数组
      arr[index1] = arr.splice(index2, 1, arr[index1])[0]
      return arr
    },
    // 初始化时，计算maxHeight和minHeight的值
    getMaxHeight() {
      // containerTop：初始化时tbody可视窗口顶部至浏览器可视窗口顶部的高度（实际值是动态变化的）；
      // clientHeight：table中tbody可视窗口的高度；
      // maxHeigh = 初始化时tbody可视窗口顶部至浏览器可视窗口顶部的高度 + tbody可视窗口的高度；
      // minHeight = 初始化时tbody可视窗口顶部至浏览器可视窗口顶部的高度；
      const containerTop = this.$refs.settingTable.$el.querySelector('.el-table__body').getBoundingClientRect().top
      const clientHeight = this.$refs.settingTable.bodyWrapper.clientHeight
      this.maxHeight = containerTop + clientHeight
      this.minHeight = containerTop
    },
    // 置顶
    top() {
      if (!this.tableRank) {
        this.$message({
          message: '请选中一行！',
          type: 'warning'
        })
        return
      }
      // 1.清除变化之前的选中行
      this.$refs.settingTable.setCurrentRow()
      // 2.利用事件机制，将选中行置顶之后，重新加上选中效果
      setTimeout(() => {
        let curIndex = this.tableData.findIndex((item, index) => {
          return item === this.selectedRow
        })
        this.$refs.settingTable.setCurrentRow(this.selectedRow)
        if (curIndex > 0) {
          let moveTime = curIndex
          for (let i = 0; i < moveTime; i++) {
            this.swapArray(this.tableData, curIndex, curIndex - 1)
            curIndex--
          }
          // scrollTop：实际放了所有数据的tbody的顶部，到浏览器可视窗口顶部的高度
          this.$refs.settingTable.bodyWrapper.scrollTop = 0
        } else {
          this.$message({
            message: '已经是第一行了！',
            type: 'warning'
          })
        }
      }, 0)
    },
    // 向上一行
    previous() {
      if (!this.tableRank) {
        this.$message({
          message: '请选中一行！',
          type: 'warning'
        })
        return
      }
      this.$refs.settingTable.setCurrentRow()
      setTimeout(() => {
        let curIndex = this.tableData.findIndex((item, index) => {
          return item === this.selectedRow
        })
        this.$refs.settingTable.setCurrentRow(this.selectedRow)
        if (curIndex > 0) {
          this.swapArray(this.tableData, curIndex, curIndex - 1)
          const targetTop = this.$refs.settingTable.$el.querySelectorAll('.el-table__body tr')[curIndex - 1].getBoundingClientRect().top
          const scrollParent = this.$refs.settingTable.$el.querySelector('.el-table__body-wrapper')
          const containerTop = this.$refs.settingTable.$el.querySelector('.el-table__body').getBoundingClientRect().top
          if (targetTop < this.minHeight) scrollParent.scrollTop = targetTop - containerTop
        } else {
          this.$message({
            message: '已经是第一行了！',
            type: 'warning'
          })
        }
      }, 0)
    },
    // 向下一行
    next() {
      if (!this.tableRank) {
        this.$message({
          message: '请选中一行！',
          type: 'warning'
        })
        return
      }
      this.$refs.settingTable.setCurrentRow()
      setTimeout(() => {
        let curIndex = this.tableData.findIndex((item, index) => {
          return item === this.selectedRow
        })
        this.$refs.settingTable.setCurrentRow(this.selectedRow)
        if (curIndex < this.tableData.length - 1) {
          this.swapArray(this.tableData, curIndex, curIndex + 1)
          const trLength = this.$refs.settingTable.$el.querySelectorAll('.el-table__body tr').length
          if (curIndex + 2 >= trLength) {
            this.$refs.settingTable.bodyWrapper.scrollTop = this.$refs.settingTable.bodyWrapper.scrollHeight
            return
          }
          const targetTop = this.$refs.settingTable.$el.querySelectorAll('.el-table__body tr')[curIndex + 2].getBoundingClientRect().top
          const scrollParent = this.$refs.settingTable.$el.querySelector('.el-table__body-wrapper')
          const containerTop = this.$refs.settingTable.$el.querySelector('.el-table__body').getBoundingClientRect().top
          const clientHeight = this.$refs.settingTable.bodyWrapper.clientHeight
          if (targetTop >= this.maxHeight) scrollParent.scrollTop = targetTop - (containerTop + clientHeight)
        } else {
          this.$message({
            message: '已经是最后一行了！',
            type: 'warning'
          })
        }
      }, 0)
    },
    // 置底
    bottom() {
      if (!this.tableRank) {
        this.$message({
          message: '请选中一行！',
          type: 'warning'
        })
        return
      }
      this.$refs.settingTable.setCurrentRow()
      setTimeout(() => {
        let curIndex = this.tableData.findIndex((item, index) => {
          return item === this.selectedRow
        })
        this.$refs.settingTable.setCurrentRow(this.selectedRow)
        if (curIndex < this.tableData.length - 1) {
          let moveTime = this.tableData.length - 1 - curIndex
          for (let i = 0; i < moveTime; i++) {
            this.swapArray(this.tableData, curIndex, curIndex + 1)
            curIndex++
          }
          this.$refs.settingTable.bodyWrapper.scrollTop = this.$refs.settingTable.bodyWrapper.scrollHeight
        } else {
          this.$message({
            message: '已经是最后一行了！',
            type: 'warning'
          })
        }
      }, 0)
    },
    // 获取初始化表格数据
    getInitTable() {
      let obj = {}
      obj.configKey = this.configKey
      this.tableData = []
      this.tableDataShow = []
      return new Promise((resolve) => {
        UCgetConfig(obj).then((res) => {
          // console.log(res)
          if (res.code === 200) {
            let list=[]
            if(res.data && res.data.configValue){
               list = JSON.parse(res.data.configValue).list
            }else{
              // 使用默认配置
              list=this.defaultList
            }
            list.forEach((item) => {
              if (item.display) {
                // 过滤掉加show的字段，例如：showType和type
                this.tableDataShow.push(item)
              } else {
                this.tableData.push(item)
              }
            })
            // 根据固定的列数序号，将该序号及之前的所有列的fixed改为true
            for (let i = 0; i < this.tableData.length; i++) {
              if (i < this.tableData.length - 1 && this.tableData[i].fixed && !this.tableData[i + 1].fixed) {
                this.fixedNum = i + 1
              }
            }
            resolve()
          } else {
            this.$message({
              message: '获取数据表格配置失败',
              type: 'error'
            })
          }
        })
      }).catch((e) => {})
    },
    // 打开列设置弹框
    showColSettings() {
      this.$nextTick(() => {
        this.dialogSetting = true
        this.getInitTable().then(() => {
          setTimeout(() => {
            this.getMaxHeight()
          }, 0)
        })
      })
    },
    // 确定
    confirm() {
      if (
        !/^[0-9]+\d*$/.test(this.fixedNum) ||
        this.fixedNum - this.tableData[this.tableData.length - 1].rank > 0
      ) {
        this.$message({
          type: 'warning',
          duration: 1500,
          message: '请输入正确的序号！'
        })
        return false
      }
      this.tableData.forEach((el, index) => {
        el.fixed = false
        el.rank = index + 1
        if (index - this.fixedNum < 0) el.fixed = true
      })
      let obj = {}
      obj.list = this.tableData.concat(this.tableDataShow)
      let obj2 = {}
      let str = JSON.stringify(obj)
      obj2.configValue = str
      obj2.configClassify = this.configClassify
      obj2.configKey = this.configKey
      // obj2.id = this.updateId
      UCsaveOrUpdate(obj2).then((res) => {
        if (res.code === 200) {
          if (typeof this.getHeader === 'function') this.getHeader()
          this.dialogSetting = false
        }
      })
    },
    // 取消
    cancel() {
      this.dialogSetting = false
      this.$nextTick(() => {
        // this.$destroy('ColumnSettings')
      })
    },
    // 恢复默认设置
    defaultSetting() {
      // console.log(this.defaultList)
      this.tableDataShow = []
      this.tableData = []
      this.defaultList.forEach(item => {
        if (item.display) this.tableDataShow.push(item)
        else this.tableData.push(item)
      })
      this.fixedNum = 1
      this.confirm()
    }
  }
}
</script>

<style scoped>
.Column_Settings >>> .el-dialog {
  width: 60%;
  height: 95%;
  max-height: 690px;
}
/* .Column_Settings >>> .el-dialog__header {
  background-color: #f2f2f2;
  border-bottom: 1px solid #dcdfe0;
} */
/* .Column_Settings >>> .el-dialog__body {
  height: 60%;
  max-height: 730px;
  height: 600px;
  overflow-y: auto;
} */
.Column_Settings .direction_btns {
  margin-top: 10px;
  width: 100%;
}
.Column_Settings .direction_btns > div {
  float: left;
  margin-left: 11.5%;
}
.Column_Settings .direction_btns > div:first-child {
  margin: 0;
}
.Column_Settings .direction_btns i {
  font-size: 10px !important;
  width: 60px !important;
  height: 20px !important;
  display: block !important;
  text-align: center;
  line-height: 20px !important;
  background-color: #f2f2f2;
  border-radius: 5px;
  border: 1px solid #e2e4e5;
  cursor: pointer;
}
/* .Column_Settings .func_btns {
  margin-top: 20px;
} */
.Column_Settings .func_btns >>> .el-button {
  float: right;
  margin-right: 20px;
}
.Column_Settings >>> .el-dialog__wrapper p {
  margin: 15px 0;
  font-weight: 600;
  font-size: 15px;
}
.Column_Settings >>> .el-dialog__body {
  padding: 15px;
}
</style>
