import request from '@/utils/request'
// import { praseStrEmpty } from '@/utils/common'


// 获取分类节点信息
export function categoryDirInfo(id) {
  return request({
    url: '/dkm/category/dir/categoryDirInfo',
    method: 'get',
    params: { id:id }
  })
}


// 药品目录新增-更新
export function editCategoryDir(data) {
  return request({
    url: '/dkm/category/dir/editCategoryDir',
    method: 'post',
    data: data
  })
}


// 根据categoryType获取树形结构
export function getTreeByCategoryType(categoryType) {
  return request({
    url: '/dkm/category/dir/getTreeByCategoryType',
    method: 'get',
    params: {categoryType:categoryType}
  })
}

// 删除药品类别目录
export function delCategoryDir(id,categoryType) {
  return request({
    url: '/dkm/category/dir/delCategoryDir',
    method: 'post',
    params: { id:id,categoryType:categoryType }
  })
}


















// 查询药品分类树
export function dirSearch(params) {
  return request({
    url: '/dkm/category/dir',
    method: 'get',
    params: params
  })
}
// 药品目录新增-更新
// export function dirAdd(data) {
//   return request({
//     url: '/dkm/category/dir',
//     method: 'post',
//     data: data
//   })
// }

// 根据药品类别目录Id 查询关联列表信息
export function info(query) {
  return request({
    url: '/dkm/category/dir/map/info',
    method: 'get',
    params: query
  })
}
// 根据查询条件,获取相关列表数据
export function filter(query) {
  return request({
    url: '/dkm/category/dir/map/filter',
    method: 'get',
    params: query
  })
}
// 新增-更新药品映射关系
export function dirMap(data) {
  return request({
    url: '/dkm/category/dir/map',
    method: 'post',
    data: data
  })
}
// 删除药品类别目录
export function dirMapDel(query) {
  return request({
    url: '/dkm/category/dir/map',
    method: 'DELETE',
    params: query
  })
}


// 检查相关数据重复项，如编辑或新增时候，检查名称、代码是否有重复等
export function checkFields(categoryType,fieldName,fieldValue,id,pid) {
  return request({
    url: '/dkm/category/dir/checkFields',
    method: 'post',
    params: {categoryType:categoryType,fieldName:fieldName,fieldValue:fieldValue,id:id,pid:pid}
  })
}




// 获取指定数据的全部分类信息
export function getDrugCategoryTree( dataType, dataId) {
  return request({
    url: '/dkm/category/dir/getDrugCategoryTree',
    method: 'post',
    params: {dataType:dataType,dataId:dataId}
  })
}

// 删除数据的分类关联关系
export function delMapping( dataType, dataId,categoryId) {
  return request({
    url: '/dkm/category/dir/delMapping',
    method: 'post',
    params: {dataType:dataType,dataId:dataId,categoryId:categoryId}
  })
}


// 根据分类id获取全部条件
export function getConditionList(categoryDirId) {
  return request({
    url: '/dkm/category/dir/getConditionList',
    method: 'get',
    params: {categoryDirId:categoryDirId}
  })
}

// 保存条件
export function editCondition(data) {
  return request({
    url: '/dkm/category/dir/editCondition',
    method: 'post',
    data: data
  })
}


// 删除条件
export function delCondition(id) {
  return request({
    url: '/dkm/category/dir/delCondition',
    method: 'post',
    params: {id:id}
  })
}



// 数据同步，根据规则重新同步数据
export function refreshDirRefData(categoryDirId) {
  return request({
    url: '/dkm/category/dir/refreshDirRefData',
    method: 'post',
    params: {categoryDirId:categoryDirId}
  })
}
