<template>
  <div ref="lineDom" class="container line-container" :style="changeThemeColor">
    <div class="timeline" :class="{mt: !carouseIsShow}">
      <div class="line full-width"></div>
      <div class="timeline-container">
        <div class="item-btn">
          <el-button
            v-if="timelineNum < timelineList.length"
            :class="{ 'item-disabled': !loop && currentIndex === 0 }"
            class="item-prev"
            icon="el-icon-caret-left"
            :disabled="!loop && currentIndex === 0"
            @click="go(prevIndex, 'prev')">
          </el-button>
          <el-button
            v-else
            :class="{ 'item-disabled': !loop && currentIndex === 0 }"
            class="item-prev-full"
            icon="el-icon-caret-left"
            :disabled="!loop && currentIndex === 0"
            @click="go(prevIndex, 'prev')">
          </el-button>
        </div>
        <div class="item">
          <div
            class="list"
            v-for="(item, index) in timelineList"
            :key="item.sort"
            @click="go(index, '')"
            @mouseover="handleMouseOver(item[showKey], index)"
            @mouseleave="handleMouseLeave(item[showKey], index)"
            :style="{ transform: `translateX(${translateNum}px)`, width: `${timelineWidth}px`,}">
            <div class="text">
              {{ item[showKey] }}
            </div>
            <div class="circle" :class="{ active: index === currentIndex }"></div>
            <div
              class="line"
              :class="{ on: index === currentIndex && onIsActive, go: index === currentIndex && !onIsActive,}"></div>
            <div ref="tip" class="tip" style="display: none;">
              {{ item[showKey] }}
              <div class="triangle"></div>
            </div>
          </div>
        </div>
        <div class="item-btn">
          <el-button
            v-if="timelineNum < timelineList.length"
            class="item-next"
            icon="el-icon-caret-right"
            :disabled="!loop && currentIndex === timelineList.length - 1"
            :class="{ 'item-disabled': !loop && currentIndex === timelineList.length - 1}"
            @click.native="go(nextIndex, 'next')">
          </el-button>
          <!--一排能放下所有的节点-->
          <el-button
            v-else
            icon="el-icon-caret-right"
            class="item-next-full"
            :disabled="!loop && currentIndex === timelineList.length - 1"
            :class="{ 'item-disabled':!loop && currentIndex === timelineList.length - 1}"
            @click.native="go(nextIndex, 'next')">
          </el-button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    timelineList: {
      type: Array,
      default() {
        return [
          {
            sort: 1,
            simpleVersion: "2020-09-28",
          },
          {
            sort: 2,
            simpleVersion: "2020-09-28",
          },
          {
            sort: 3,
            simpleVersion: "20210909",
          },
          {
            sort: 4,
            simpleVersion: "04",
          },
          {
            sort: 5,
            simpleVersion: "05",
          },
          {
            sort: 6,
            simpleVersion: "06",
          },
          {
            sort: 7,
            simpleVersion: "07",
          },
          {
            sort: 8,
            simpleVersion: "08",
          },
          {
            sort: 9,
            simpleVersion: "09",
          },
          {
            sort: 10,
            simpleVersion: "10",
          },
        ];
      },
    },
    // 显示的时间的字段名称
    showKey: {
      type: String,
      required: false,
      default: 'simpleVersion'
    },
    autoPlay: {
      type: Boolean,
      default: false,
    },
    loop: {
      type: Boolean,
      default: false,
    },
    carouselList: {
      type: Array,
      default() {
        return [
          // {
          //   id: 1,
          //   img: require("../assets/images/1.jpg"),
          //   title: "陶渊明",
          //   info: "盛年不重来，一日难再晨。及时宜自勉，岁月不待人。",
          // },
          // {
          //   id: 2,
          //   img: require("../assets/images/2.jpg"),
          //   title: "老子",
          //   info: "千里之行，始于足下。",
          // },
          // {
          //   id: 3,
          //   img: require("../assets/images/3.jpg"),
          //   title: "朱熹",
          //   info: "少年易学老难成，一寸光阴不可轻。",
          // },
          // {
          //   id: 4,
          //   img: require("../assets/images/4.jpg"),
          //   title: "王勃",
          //   info: "海内存知已，天涯若比邻。",
          // },
          // {
          //   id: 5,
          //   img: require("../assets/images/5.jpg"),
          //   title: "高适",
          //   info: "莫愁前路无知已，天下谁人不识君。",
          // },
          // {
          //   id: 6,
          //   img: require("../assets/images/6.jpg"),
          //   title: "孟子",
          //   info: "穷则独善其身，达则兼济天下。",
          // },
          // {
          //   id: 7,
          //   img: require("../assets/images/7.jpg"),
          //   title: "杜甫",
          //   info: "读书破万卷，下笔如有神。",
          // },
          // {
          //   id: 8,
          //   img: require("../assets/images/1.jpg"),
          //   title: "陶渊明",
          //   info: "盛年不重来，一日难再晨。及时宜自勉，岁月不待人。",
          // },
          // {
          //   id: 9,
          //   img: require("../assets/images/2.jpg"),
          //   title: "老子",
          //   info: "千里之行，始于足下。",
          // },
          // {
          //   id: 10,
          //   img: require("../assets/images/3.jpg"),
          //   title: "朱熹",
          //   info: "少年易学老难成，一寸光阴不可轻。",
          // }
        ]
      },
    },
    // timelineNum: {
    //   type: Number,
    //   default: 8,
    // },
    timelineWidth: {
      type: Number,
      default: 120,
    },
    themeColor: {
      type: String,
      default: "#94AFF7",
    },
    activeColor: {
      type: String,
      default: '#2B60F8'
    },
    enterActiveClass: {
      type: String,
      default: 'animate__animated animate__fadeInLeft'
    },
    mouseEvent: {
      type: Boolean,
      default: false
    },
    carouseIsShow: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      currentIndex: 0,
      timer: null,
      timer2: null,
      onIsActive: true,
      translateNum: 0,
      totalWidth: 0,
      timelineNum: 1
    };
  },
  computed: {
    prevIndex() {
      if (!this.loop) {
        if (this.currentIndex == 0) {
          return 0;
        } else {
          return this.currentIndex - 1;
        }
      } else {
        if (this.currentIndex == 0) {
          return this.timelineList.length - 1;
        } else {
          return this.currentIndex - 1;
        }
      }
    },
    nextIndex() {
      if (!this.loop) {
        if (this.currentIndex == this.timelineList.length - 1) {
          return this.timelineList.length - 1;
        } else {
          return this.currentIndex + 1;
        }
      } else {
        if (this.currentIndex == this.timelineList.length - 1) {
          return 0;
        } else {
          return this.currentIndex + 1;
        }
      }
    },
    changeThemeColor() {
      return {
        "--theme-color": this.themeColor,
        "--active-color": this.activeColor
      }
    },
    // timelineNum: {
    //   // 可视区域可以放下几个节点
    //   get() {
    //     let num = Math.floor(this.totalWidth / this.timelineWidth)
    //     console.log(num)
    //     if (this.$refs.lineDom) {
    //       let num = Math.floor(this.$refs.lineDom.offsetWidth / this.timelineWidth)
    //     }
    //     return num - 1
    //   },
    //   set(num) {
    //     return num
    //   }
    // }
  },
  watch: {
    timelineNum(val, oldVal) {
      // console.log(val, oldVal)
    },
    timelineList: {
      handler(newVal, oldVal) {
        this.currentIndex = this.timelineList.length - 1
        this.calcTranslateNum()
      },
      deep: true
    }
  },
  mounted() {
    if (this.autoPlay) {
      this.setGo();
      this.setOnActive();
    }
    this.$nextTick(() => {
      // console.log($('.mt').eq(0).width())
      this.totalWidth = $('.mt').eq(0).width()
      let num = Math.floor(this.totalWidth / this.timelineWidth)
      // console.log(num)
      if (this.$refs.lineDom) {
        let num = Math.floor(this.$refs.lineDom.offsetWidth / this.timelineWidth)
      }
      this.timelineNum = num - 1
    })
    // this.$emit("getCurrentItem", 0, this.timelineList[0], this.carouselList[0]);
  },
  beforeDestroy() {
    clearInterval(this.timer);
    clearTimeout(this.timer2);
  },
  created() {
    if (this.$refs.lineDom) {
      let num = Math.floor(this.$refs.lineDom.offsetWidth / this.timelineWidth)
      this.timelineNum = num - 1
    }
    const that = this
    window.addEventListener('resize', () => {
      if (that.$refs.lineDom) {
        let num = Math.floor(that.$refs.lineDom.offsetWidth / that.timelineWidth)
        that.timelineNum = num - 1
      }
    }, false)
    this.calcTranslateNum()
  },
  destroyed(){
    window.onresize = null
  },
  methods: {
    go(index, str) {
      this.currentIndex = index;
      this.onIsActive = true;
      if (this.autoPlay) {
        // this.setGo();
        this.setOnActive();
      }
      this.calcTranslateNum(str);
      this.$emit("getCurrentItem", index, this.timelineList[index], this.carouselList[index]);
    },
    setOnActive() {
      clearTimeout(this.timer2);
      this.timer2 = setTimeout(() => {
        this.onIsActive = false;
      }, 2800);
    },
    setGo() {
      clearInterval(this.timer);
      this.timer = setInterval(() => {
        this.go(this.nextIndex);
        this.calcTranslateNum("next");
      }, 3600);
    },
    calcTranslateNum(str) {
      const length = this.timelineList.length - 1;
      if (str === "next") {
        // if (this.currentIndex === 0) {
        //   this.translateNum = 0
        // } else {
        //   this.translateNum = (this.currentIndex + 1) * -this.timelineWidth
        // }
        if (this.currentIndex >= this.timelineNum) {
          this.translateNum = -this.timelineWidth * this.currentIndex
          // this.translateNum = (this.currentIndex + 0.5) * -this.timelineWidth
        }
        if (this.currentIndex === 0) {
          this.translateNum = 0;
        }
      } else if (str === "prev") {
        if (this.currentIndex <= this.timelineNum) {
          this.translateNum = 0
          return
        }
        // if (this.currentIndex > this.timelineNum) {
        //   this.translateNum = (length - this.timelineNum) * -this.timelineWidth
        //   return
        // }
        this.translateNum += this.timelineWidth
      } else {
        if (this.currentIndex < this.timelineNum) {
          this.translateNum = 0
          return
        }
        if (this.currentIndex >= this.timelineNum) {
          // this.translateNum = (length + 1 - this.timelineNum) * -this.timelineWidth
          this.translateNum = -this.timelineWidth * this.currentIndex
          return
        }
        this.translateNum += this.timelineWidth
      }
    },
    handleMouseOver(text, index) {
      // if (text.length >= 16) {
      //   this.$refs.tip[index].style = "display: block;";
      // }
      const textWidth = document.getElementsByClassName('text')[0].offsetWidth
    },
    handleMouseLeave(text, index) {
      // if (text.length >= 16) {
      //   this.$refs.tip[index].style = "display: none;";
      // }
    },
    handleSliderMouseOver() {
      if (this.autoPlay && this.mouseEvent) {
        clearInterval(this.timer);
        clearTimeout(this.timer2);
      }
    },
    handleSliderMouseLeave() {
      if (this.autoPlay && this.mouseEvent) {
        this.setGo();
        this.setOnActive();
      }
    },
  }
};
</script>
<style lang='scss' scoped>
* {
  margin: 0;
  padding: 0;
}
.btn-wrap {
  width: 36px;
  height: 50px;
  background: #FFFFFF;
  box-shadow: -2px 0px 8px 0px rgba(161, 171, 203, 0.8);
  border-radius: 4px;
}
.container {
  width: 100%;
  height: 50px;
  .slider {
    width: 100%;
    padding: 12% 15% 4% 15%;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    span {
      display: flex;
      justify-content: center;
      align-items: center;
      overflow: hidden;
      height: 260px;
    }
    .leave-to {
      position: relative;
    }
    .enter-active-class {
      animation: 1s fadeIn linear;
    }
    .slider-box {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      &.active {
        animation: 1s fadeIn linear;
      }
      .slider-box__item {
        width: 50%;
        height: 100%;
        &:nth-child(2) {
          padding-left: 10%;
          box-sizing: border-box;
          position: relative;
          .item-title-bg {
            position: absolute;
            color: rgba(0, 0, 0, 0.05);
            right: 0;
            top: 0;
            font-size: 5rem;
            filter: blur(3px);
            opacity: 1;
            width: 100%;
            text-align: center;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
          }
          .item-title {
            font-size: 24px;
            font-weight: bold;
            color: #000;
            margin-bottom: 30px;
          }
          .item-info {
            font-size: 14px;
            color: #5a5b5b;
            line-height: 24px;
          }
        }
        img {
          width: 100%;
        }
      }
    }
  }
  .timeline {
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    &.mt {
      margin-top: 0px;
    }
    .line {
      width: 100%;
      height: 2px;
      background: #D2DDFA;
      margin-top: 30px;
    }
    .timeline-container {
      width: 100%;
      position: relative;
      .item {
        /* width: 12.5%; */
        float: left;
        /* position: fixed; */
        margin-top: -46px;
        width: 36px;
        height: 50px;
        /* position: absolute;
        left: 0;
        top: 0;
        width: 12.5%; */
        text-align: center;
        &:nth-child(2) {
          width: calc(100% - 72px);
          display: flex;
          justify-content: space-between;
          align-items: center;
          overflow: hidden;
          /* padding-top: 150px;
          margin-top: -150px; */
          margin-top: -37px;
        }
        &:nth-child(3) {
          right: 8px;
        }
        .list {
          position: relative;
          text-align: center;
          cursor: pointer;
          transition: 0.5s all linear;
          display: flex;
          justify-content: center;
          &:last-child {
            .line {
              display: none;
            }
          }
          .circle {
            position: absolute;
            left: 48%;
            width: 16px;
            height: 16px;
            border-radius: 50%;
            margin: 2px auto 0;
            z-index: 2;
            width: 16px;
            background: #FFFFFF;
            border: 2px solid #94AFF7;
            box-shadow: 0px 0px 10px 0px rgba(26, 79, 255, 0.8);
            &.active {
              background-color: var(--active-color);
              /* border: 2px solid var(--active-color); */
            }
            &:hover {
              background-color: var(--active-color);
              /* border: 2px solid var(--active-color); */
            }
          }
          .line {
            width: 100%;
            height: 2px;
            position: absolute;
            left: 48%;
            top: -20px;
            opacity: 0;
            z-index: 1;
            &::after {
              /* content: "";
              position: absolute;
              width: 100%;
              height: 100%;
              top: 0;
              left: 0;
              background-color: var(--active-color);
              animation-fill-mode: both;
              animation-duration: 2.8s;
              animation-timing-function: linear; */
            }
          }
          .line.on {
            opacity: 1;
            &::after {
              animation-name: line_1;
            }
          }
          .line.go {
            opacity: 1;
            &::after {
              right: 0;
              left: auto;
              animation-duration: 0.8s;
              animation-name: line_2;
            }
          }
          .text {
            margin-top: -20px;
            font-size: 14px;
            width: 150px;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            position: relative;
          }
          .tip {
            position: absolute;
            top: -55px;
            padding: 10px 20px;
            border-radius: 5px;
            background-color: var(--theme-color);
            color: #fff;
            width: max-content;
            display: none;
            .triangle {
              width: 0;
              height: 0;
              border-top: 8px solid var(--theme-color);
              border-right: 5px solid transparent;
              border-left: 5px solid transparent;
              position: absolute;
              left: 50%;
              bottom: -8px;
              margin-left: -3.5px;
            }
          }
        }
      }
      .item-btn {
        float: left;
        margin-top: -32px;
        width: 36px;
        height: 50px;
        text-align: center;
        .item-prev,
        .item-next {
          width: 36px;
          height: 50px;
          background: #FFFFFF;
          box-shadow: -2px 0px 8px 0px rgba(161, 171, 203, 0.8);
          border-radius: 4px;
          &:hover {
            color: #fff;
            background-color: var(--theme-color);
          }
          &.item-disabled {
            background: #999;
            cursor: no-drop;
          }
          ::v-deep .el-icon-caret-right, ::v-deep .el-icon-caret-left {
            color: var(--active-color);
          }
        }
        .item-prev-full,
        .item-next-full {
          width: 36px;
          height: 50px;
          background: #FFFFFF;
          font-size: 26px;
          border: none;
          &:hover {
            color: var(--theme-color);
            /* background-color: var(--theme-color); */
          }
          &.item-disabled {
            /* background: #666; */
            cursor: no-drop;
          }
          ::v-deep.el-icon-caret-right, ::v-deep.el-icon-caret-left {
            margin-top: 11px;
            display: inline-block;
            color: #AFC4FA;
            position: relative;
            &:hover {
              color: var(--active-color);
            }
            &::after {
              content: '';
              display: inline-block;
              width: 15px;
              height: 2px;
              background-color: #D2DDFA;
              position: absolute;
              top: 13px;
              left: 17px;
            }
          }
          ::v-deep .el-icon-caret-right {
            &::after {
              left: -5px;
            }
          }
        }
        .item-prev,
        .item-next {
          width: 36px;
          height: 50px;
          background: #FFFFFF;
          box-shadow: -2px 0px 8px 0px rgba(161, 171, 203, 0.8);
          border-radius: 4px;
          &:hover {
            color: #fff;
            background-color: var(--theme-color);
          }
          &.item-disabled {
            background: #999;
            cursor: no-drop;
          }
          ::v-deep .el-icon-caret-right, ::v-deep .el-icon-caret-left {
            color: var(--active-color);
          }
        }
      }
    }
  }
}
@keyframes line_1 {
  0% {
    width: 0%;
  }
  100% {
    width: 100%;
  }
}
@keyframes line_2 {
  0% {
    width: 100%;
  }
  100% {
    width: 0%;
  }
}
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
</style>