var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "pagination-container",
      class: {
        hidden: _vm.hidden,
        "crm-pagination":
          _vm.settings && _vm.settings.sideTheme === "theme-crm",
      },
    },
    [
      _c(
        "el-pagination",
        _vm._b(
          {
            attrs: {
              small: _vm.isSmall,
              background: _vm.background,
              "current-page": _vm.currentPage,
              "page-size": _vm.pageSize,
              layout: _vm.layout,
              "page-sizes": _vm.pageSizes,
              total: _vm.total,
              "pager-count": _vm.pagerCount,
            },
            on: {
              "update:currentPage": function ($event) {
                _vm.currentPage = $event
              },
              "update:current-page": function ($event) {
                _vm.currentPage = $event
              },
              "update:pageSize": function ($event) {
                _vm.pageSize = $event
              },
              "update:page-size": function ($event) {
                _vm.pageSize = $event
              },
              "size-change": _vm.handleSizeChange,
              "current-change": _vm.handleCurrentChange,
              "prev-click": _vm.handlePrevClick,
              "next-click": _vm.handleNextClick,
            },
          },
          "el-pagination",
          _vm.$attrs,
          false
        )
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }