 /**
 * 操作权限处理
 * 
 */

 import store from '@/store'
 import { getCache } from '@/utils/cacheUtil'

export default {
  inserted(el, binding, vnode) {
    //console.log(el,binding,vnode)
    const { value } = binding
    const all_permission = "*:*:*";
    let permissions = store.getters && store.getters.permissions
    if (permissions.length === 0) {
      permissions = getCache('SET_PERMISSIONS')
    }
    console.log(value)
    console.log(permissions)
    if (value && value instanceof Array && value.length > 0) {
      const permissionFlag = value

      const hasPermissions = permissions.some(permission => {
        return all_permission === permission || permissionFlag.includes(permission)
      })
      console.log(hasPermissions)
      if (!hasPermissions) {
        // el.parentNode && el.parentNode.removeChild(el)
        el.setAttribute('disabled', true)
        el.classList.add('is-disabled')
        // el.children && el.children.setAttribute('disbaled', true)
        el.querySelector("input") &&  el.querySelector("input").setAttribute('disabled', 'disabled')
        // el.querySelector("input") &&  el.querySelector("input").setAttribute('disabled', true)
        console.log(el)
        console.log(el.querySelector("input") )
      }
    } else {
      throw new Error(`请设置操作权限标签值`)
    }
  }
}
