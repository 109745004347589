import axios from 'axios'
import { Notification, MessageBox, Message, Loading } from 'element-ui'
import store from '@/store'
import { getToken, removeToken } from '@/utils/auth'
import errorCode from '@/utils/errorCode'
import eventBus from '../utils/eventBus'
import Cookies from "js-cookie";
import qs from 'qs'
import { pushRoute } from '@/utils/routerUtil'
axios.defaults.headers['Content-Type'] = 'application/json;charset=utf-8'
// 创建axios实例
const timeout = 60000
const service = axios.create({
  // axios中请求配置有baseURL选项，表示请求URL公共部分
  baseURL: process.env.VUE_APP_BASE_API,
  // 超时
  timeout
})
//在window对象上绑定当前环境，普通js文件可以使用window.VUE_APP_BASE_API获取到当前环境
window.VUE_APP_BASE_API=process.env.VUE_APP_BASE_API
window.axios=axios
let requestConfig = null
let loadingInstance = null
// request拦截器
service.interceptors.request.use(config => {
  if (config.loading === true) {
    loadingInstance = Loading.service({
      lock: true,
      text: '数据加载中...',
      // spinner: 'my-el-custom-spinner',
      spinner: 'el-icon-loading',
      background: 'hsla(0,0%,100%,.9)',
      customClass: 'global-loading'
      // background: 'red'
      // target: document.querySelector('.loading-area')
    })
    console.log(service)
    // 长时间请求未成功则自动关闭
    setTimeout(() => loadingInstance.close(), timeout)
  }
  // 是否需要设置 token
  requestConfig = config
  const isToken = (config.headers || {}).isToken === false
  if (getToken() && !isToken) {
    config.headers['Authorization'] = 'Bearer ' + getToken() // 让每个请求携带自定义token 请根据实际情况自行修改
  }
  // get请求映射params参数
  /* if (config.method === 'get' && config.params) {
    let url = config.url + '?';
    for (const propName of Object.keys(config.params)) {
      const value = config.params[propName];
      var part = encodeURIComponent(propName) + "=";
      if (value !== null && typeof(value) !== "undefined") {
        if (typeof value === 'object') {
          for (const key of Object.keys(value)) {
            let params = propName + '[' + key + ']';
            var subPart = encodeURIComponent(params) + "=";
            url += subPart + encodeURIComponent(value[key]) + "&";
          }
        } else {
          url += part + encodeURIComponent(value) + "&";
        }
      }
    }
    url = url.slice(0, -1);
    config.params = {};
    config.url = url;
  } */
  config.paramsSerializer = function (params) {
    return qs.stringify(params, {
      indices: false
    })
  }
  return config
}, error => {
    console.log(error)
    Promise.reject(error)
})

// 响应拦截器
service.interceptors.response.use(res => {

  if (loadingInstance) {
    loadingInstance.close()
  }
    // 未设置状态码则默认成功状态

    const code = res.data.code || 200;
    // 获取错误信息
    const msg = errorCode[code] || res.data.msg || errorCode['default']
    if (code === 401) {
      MessageBox.confirm('登录状态已过期，您可以继续留在该页面，或者重新登录', '系统提示', {
          confirmButtonText: '重新登录',
          cancelButtonText: '取消',
          type: 'warning'
        }
      ).then(() => {
        //主框架 注销登录
        try{
          store.dispatch('LogOut').then(() => {
            //location.href =location.pathname+'/index';
            pushRoute({ path: '/login' })
          })
        }catch(e){
          console.log("异常",e)
        }


      })
    } else if (code === 500) {
      if (requestConfig.returnError) {
        return res.data
      }
      if (!requestConfig.noGloablErrMsg) {
        Message({
          message: msg,
          type: 'error'
        })
      }
      return Promise.reject(new Error(msg))
    } else if (code === 6666 && requestConfig.needSpical) {
      return res.data
    } else if (code === 10017 && requestConfig.needSpical) {
      return res.data
    } else if (code === 10000 && requestConfig.needSpical) {
      return res.data
    } else if (code === 10011 && requestConfig.needSpical) {
      return res.data
    } else if (code === 20011) {
      return res.data
    } else if (code === 7777) {
      const userName = JSON.parse(res.config.data).username
      Cookies.set("username", userName, { expires: 30 })
      // 首次登录，强制修改密码
      MessageBox.alert('首次登录，需要修改密码', '', {
        confirmButtonText: '确定',
        callback: action => {
          // this.$message({
          //   type: 'info',
          //   message: `action: ${ action }`
          // })
          eventBus.$emit('toEditPwd')
        }
      })
    } else if (code === 18888) {
      // 上传文件错误,需要下载错误明细对应的文件
      return res.data
    } else if (code === 10002 && requestConfig.needSpical) {
      return res.data
    } else if (code === 10004 && requestConfig.needSpical) {
      return res.data
    } else if (code === 10006 && requestConfig.needSpical) {
      return res.data
    } else if (code>=5100 && code<=5190) {
      // api网关使用的错误代码范围
      return res.data
    } else if (code !== 200) {
      if (!requestConfig.insideError) {
        // 如果接口处理报错需要在页面中写代码，则需要在接口配置里加上insideError: true
        // Notification.error({
        //   title: msg
        // })
        Message.error(msg)
      }
      return res.data
      // return Promise.reject('error')
    } else if (!res.data.code && res.config.responseType === 'blob' && res.config.resopnseReturn) {
      // 场景有：下载文件，可以通过res.headers中获取后台提供的文件名
      return res
    } else {
      return res.data
    }
    return res.data
  },
  error => {
    if (loadingInstance) {
      loadingInstance.close()
    }
    console.log('err' + error)
    let { message } = error;
    if (message == "Network Error") {
      message = "后端接口连接异常";
    }
    else if (message.includes("timeout")) {
      message = "系统接口请求超时";
    }
    else if (message.includes("Request failed with status code")) {
      message = "系统接口" + message.substr(message.length - 3) + "异常";
    }
    Message({
      message: message,
      type: 'error',
      duration: 5 * 1000
    })
    return Promise.reject(error)
  }
)

export default service
