/**
 * 系统缓存工具类
 *
 *  2021-04-22  wuzhipeng
 */

import store from '@/store/index'
import { isEmpty } from '@/utils/common'

//获取缓存
export function getCache(key) {
  //return store.state.sysCache.caches[key]
  if (window.sessionStorage) {
    let val = window.sessionStorage.getItem(key)
    if (isEmpty(val)) {
      return undefined
    }
    return JSON.parse(val)
  } else {
    alert("浏览器暂不支持localStorage")
  }

}

//设置缓存
export function setCache(key, value) {

  //store.dispatch('sysCache/setKey', { key: key, value: value })
  if (window.sessionStorage) {
    //value转换为json字符串存储
    if (isEmpty(value)) {
      value = ""
    }
    window.sessionStorage.setItem(key, JSON.stringify(value))
  } else {
    alert("浏览器暂不支持localStorage")
  }
}
