<template>
  <transition-group
    style="overflow: hidden;width: 100%;height: 100%"
    name="fade-transform" mode="out-in">
    <inner-link
      v-for="(item, index) in iframeViews"
      :key="item.path"
      :name="item.path"
      :iframeId="'iframe' + index"
      v-show="$route.path === item.path"
      :src="getRealUrl(item)"
    ></inner-link>
  </transition-group>
</template>

<script>
import InnerLink from "../InnerLink/index"

export default {
  components: { InnerLink },
  computed: {
    iframeViews() {
      return this.$store.state.tagsView.iframeViews
    }
  },
  methods: {
    console(msg) {
      console.log("console:", msg)
    },
    //获取转换过的url，应对 /hello/detail/123 这种路由
    getRealUrl(route){
        let lastIndex=route.path.lastIndexOf('/')
        let params=route.path.substring(lastIndex+1)
        let url=route.meta.url
        url=url.replace("*",params)
        // console.log(route)
        // console.log(url)
        return url
    }
  }
}
</script>
