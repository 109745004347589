<template>
  <div class="navbar">
    <el-row>
      <el-col :span="1">
        <hamburger id="hamburger-container" :is-active="sidebar.opened" class="hamburger-container" @toggleClick="toggleSideBar" />
      </el-col>
      <el-col :span="20">
        <tags-view />
      </el-col>
      <el-col :span="3">
        <div class="right-menu">
          <template v-if="device!=='mobile'">
<!--            <search id="header-search" class="right-menu-item" />-->


<!--            <screenfull id="screenfull" class="right-menu-item hover-effect" />-->

<!--            <el-tooltip content="布局大小" effect="dark" placement="bottom">
              <size-select id="size-select" class="right-menu-item hover-effect" />
            </el-tooltip>-->

          </template>

          <el-dropdown class="avatar-container right-menu-item hover-effect" trigger="click">
            <div class="avatar-wrapper" style="width:250px;text-align:right;">
<!--              <img :src="avatar" class="user-avatar">-->
              <i class="el-icon-s-custom userIcon"></i>
              <span class="userName">{{ name }}({{ deptName }})</span>
              <i class="el-icon-caret-bottom" />
            </div>
            <el-dropdown-menu slot="dropdown">
              <router-link to="/user/profile">
                <el-dropdown-item>个人中心</el-dropdown-item>
              </router-link>
              <el-dropdown-item @click.native="setting = true" style="display: none;">
                <span>布局设置</span>
              </el-dropdown-item>
              <el-dropdown-item divided @click.native="logout">
                <span>退出登录</span>
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
      </el-col>
    </el-row>


<!--<breadcrumb id="breadcrumb-container" class="breadcrumb-container" />-->


  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import Breadcrumb from '@/components/Breadcrumb'
import Hamburger from '@/components/Hamburger'
import Screenfull from '@/components/Screenfull'
import SizeSelect from '@/components/SizeSelect'
import Search from '@/components/HeaderSearch'
import TagsView  from './TagsView'
import Cookies from "js-cookie";
import { pushRoute } from '@/utils/routerUtil'

export default {
  components: {
    Breadcrumb,
    Hamburger,
    Screenfull,
    SizeSelect,
    Search,
    TagsView
  },
  computed: {
    ...mapGetters([
      'sidebar',
      'avatar',
      'device',
      'name',
      'deptName'
    ]),
    setting: {
      get() {
        return this.$store.state.settings.showSettings
      },
      set(val) {
        this.$store.dispatch('settings/changeSetting', {
          key: 'showSettings',
          value: val
        })
      }
    },
    // userName: {
    //   get() {
    //     return Cookies.get("username")
    //   }
    // }
  },
  methods: {
    toggleSideBar() {
      this.$store.dispatch('app/toggleSideBar')
      //左侧菜单栏展开关闭时候，手动触发resize事件
      if(document.createEvent) {
        var event = document.createEvent("HTMLEvents");
        event.initEvent("resize", true, true);
        window.dispatchEvent(event);
      } else if(document.createEventObject) {
        window.fireEvent("onresize");
      }
    },
    async logout() {
      this.$confirm('确定注销并退出系统吗？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$store.dispatch('LogOut').then(() => {
          pushRoute({ path: '/login' })
        })
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.navbar {
  height: 50px;
  /*overflow: hidden;*/
  position: relative;
  background: #fff;
  box-shadow: 0 1px 4px rgba(0,21,41,.08);
  .hamburger-container {
    line-height: 46px;
    height: 100%;
    float: left;
    cursor: pointer;
    transition: background .3s;
    -webkit-tap-highlight-color:transparent;

    &:hover {
      background: rgba(0, 0, 0, .025)
    }
  }

  .breadcrumb-container {
    float: left;
  }

  .errLog-container {
    display: inline-block;
    vertical-align: top;
  }

  .right-menu {
    float: right;
    height: 50px;
    line-height: 50px;

    &:focus {
      outline: none;
    }
    .userIcon{
      color: #1243BA;
      font-size:22px;
      //display:inline-block;
    }
    .userName{
      color: #333333;
      font-size:14px;
      margin-left: 10px;
      //width: 100px;
      //overflow: hidden;
      //text-overflow: ellipsis;
      //white-space: nowrap;
      //display: inline-block;
      //float:right;
    }
    .right-menu-item {
      display: inline-block;
      padding: 0 8px;
      height: 100%;
      font-size: 18px;
      color: #5a5e66;
      vertical-align: text-bottom;

      &.hover-effect {
        cursor: pointer;
        transition: background .3s;

        //&:hover {
        //  background: rgba(0, 0, 0, .025)
        //}
      }
    }

    .avatar-container {
      margin-right: 30px;

      .avatar-wrapper {
        margin-top: 5px;
        position: relative;

        .user-avatar {
          cursor: pointer;
          width: 40px;
          height: 40px;
          border-radius: 10px;
        }

        .el-icon-caret-bottom {
          cursor: pointer;
          position: absolute;
          right: -20px;
          top: 25px;
          font-size: 12px;
        }
      }
    }
  }
}
</style>
