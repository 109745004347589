/**
 * 深拷贝对象，可以正确序列化日期
 * @param {*} obj
 */
 export const DEEP_CLONE = function (obj) {
    let objClone = Array.isArray(obj) ? [] : {}
    if (obj && typeof obj === 'object') {
      for (let key in obj) {
        if (obj.hasOwnProperty(key)) {
          // 判断ojb子元素是否为对象，如果是，递归复制
          if (obj[key] && typeof obj[key] === 'object') {
            // 深拷贝日期类型
            if (obj[key] instanceof Date) {
              objClone[key] = new Date(obj[key].valueOf())
              // console.log('deepClone', objClone[key])
            } else {
              objClone[key] = DEEP_CLONE(obj[key])
            }
          } else {
            // 如果不是，简单复制
            objClone[key] = obj[key]
          }
        }
      }
    }
    return objClone
  }

/**
 *  全局配置 configKey值和configName值
 * 命名规范: "模块名称-页面名称-功能名称"
 * "configName": "物质管理-物质列表-表格配置"
 * "configKey": "substance:list:table"
 */
export const GLOBAL_CONFIGURATION_CONFIGKEY = [
    // 物质管理-物质列表-表格配置
    {
        id: 1,
        configName: "物质管理-物质列表-表格配置",
        configKey: "substance:list:table",
        configClassify: 1
    }, {
    // 药品管理-药品列表-表格配置
        id: 2,
        configName: "药品管理-药品列表-表格配置",
        configKey: "drugProd:list:table",
        configClassify: 1
    }, {
    // 药物管理-药物列表-表格配置
        id: 3,
        configName: "药物管理-药物列表-表格配置",
        configKey: "drug:list:table",
        configClassify: 1
    }, {
    // 药物管理-药物列表-表格配置
        id: 4,
        configName: "药物组管理-药物组列表-表格配置",
        configKey: "drugGroup:list:table",
        configClassify: 1
    }, {
      // 制剂规格管理-制剂列表-表格配置
      id: 5,
      configName: "制剂规格管理-制剂列表-表格配置",
      configKey: "preparationSpecification:list:table",
      configClassify: 1
    }, {
      id: 10,
      configName: "药品管理-药品列表-表格配置",
      configKey: "drugProd:list:table",
      configClassify: 1
    }, {
    id: 11,
    configName: "组合包装药品管理-药品列表-表格配置",
    configKey: "pkgDrugProd:list:table",
    configClassify: 1
    }, {
      id: 31,
      configName: "字典-厂商字典-表格配置",
      configKey: "manufacturer:list:table",
      configClassify: 1
    }, {
      id: 41,
      configName: "药品元数据管理-上传文档预处理-表格配置",
      configKey: "pretreatment:list:table",
      configClassify: 1
    }, {
    id: 51,
    configName: "上海医保药品--上传记录列表-表格配置",
    configKey: "smpaDrug:uploadList",
    configClassify: 1
  }, {
    id: 52,
    configName: "上海医保药品--药品列表-表格配置",
    configKey: "smpaDrug:drugList",
    configClassify: 1
  }, {
    id: 53,
    configName: "国家医保药品--上传记录列表-表格配置",
    configKey: "nhsaDrug:uploadList",
    configClassify: 1
  }, {
    id: 54,
    configName: "国家医保药品--药品列表-表格配置",
    configKey: "nhsaDrug:drugList",
    configClassify: 1
  },
  {
    id: 55,
    configName: "CFDA药品--上传记录列表-表格配置",
    configKey: "cfdaDrug:uploadList",
    configClassify: 1
  }, {
    id: 56,
    configName: "CFDA药品--药品列表-表格配置",
    configKey: "cfdaDrug:drugList",
    configClassify: 1
  },
  {
    id: 70,
    configName: "api网关--全部api列表-表格配置",
    configKey: "apiGateway:apiList",
    configClassify: 1
  },
  {
    id: 55,
    configName: "平台数据治理--审核治理报告-表格配置",
    configKey: "platform:report",
    configClassify: 1
  },
  {
    id: 71,
    configName: "开发者用户管理--全部api列表-表格配置",
    configKey: "appUserRegistration:userList",
    configClassify: 1
  },
  {
    id: 72,
    configName: "api网关--消息推送历史记录-表格配置",
    configKey: "apiGateway:pushMsgLogList",
    configClassify: 1
  },
  {
    id: 73,
    configName: "用户行为记录--记录列表-表格配置",
    configKey: "sysActionRecord:recordList",
    configClassify: 1
  },
  {
    id: 91,
    configName: "段落管理--段落列表-表格配置",
    configKey: "paragraphManagement:paragraphList",
    configClassify: 1
  },
  {
    id: 92,
    configName: "模版管理--模版列表-表格配置",
    configKey: "templateManagement:templateList",
    configClassify: 1
  },
  {
    id: 93,
    configName: "模版审核--审核列表-表格配置",
    configKey: "templateReview:reviewList",
    configClassify: 1
  },
  {
    id: 94,
    configName: "素材管理--素材列表-表格配置",
    configKey: "materialManagement:materialList",
    configClassify: 1
  },
  {
    id: 95,
    configName: "内容管理--文档列表-表格配置",
    configKey: "contentManagement:contentList",
    configClassify: 1
  },
  {
    id: 96,
    configName: "内容审核--文档列表-表格配置",
    configKey: "contentReview:contentList",
    configClassify: 1
  },
  {
    id: 97,
    configName: "视图模版--视图模版列表-表格配置",
    configKey: "viewTemplateManagement:viewTemplateList",
    configClassify: 1
  },
  {
    id: 98,
    configName: "条件组管理--条件组列表-表格配置",
    configKey: "conditionGroupManagement:conditionGroupList",
    configClassify: 1
  }
    ]
/**
 *  配置分类字典
 */
export const CONFIGURE_CLASSIFICATION_DICTIONARY = [
    {
        key: 1,
        val: '表格自定义配置'
    },{
        key: 2,
        val: '系统首页自定义配置'
    }]
/**
 * el-col规格配置，存放各个尺寸下生成的col的样式前缀，以及一行默认的col列数
 * xs: <1000；s: >1000；m: >1054；l: >1470；x: >1920
 */
export const EL_COL_CONFIG = [
  {
    symbol: 'xs',
    class: 'el-col-c-xs-',
    col: 1
  }, {
    symbol: 's',
    class: 'el-col-c-s-',
    col: 1
  }, {
    symbol: 'm',
    class: 'el-col-c-m-',
    col: 1
  }, {
    symbol: 'l',
    class: 'el-col-c-l-',
    col: 1
  }, {
    symbol: 'x',
    class: 'el-col-xl-',
    col: 1
  }]
/**
 * el-col规格配置，存放各个尺寸下生成的col的样式前缀，以及一行默认的col列数
 * xs: <1000；s: >1000；m: >1054；l: >1470；x: >1920
 */
export const EL_COL_VCONFIG = [
  {
    symbol: 'xs',
    class: 'el-col-v-xs-',
    col: 1
  }, {
    symbol: 's',
    class: 'el-col-v-s-',
    col: 1
  }, {
    symbol: 'm',
    class: 'el-col-v-m-',
    col: 1
  }, {
    symbol: 'l',
    class: 'el-col-v-l-',
    col: 1
  }, {
    symbol: 'x',
    class: 'el-col-v-xl-',
    col: 1
  }]

/**
 * el-col中列与栅格的对应关系
 * @param {Number} colNumber 每行列数
 */
export const EL_COL_GRID = function (colNumber) {
  switch (colNumber) {
    case 1:
      return 24
    case 2:
      return 12
    case 3:
      return 8
    case 4:
      return 6
    // 5列跟6列配置相同
    case 5:
      return 4
    case 6:
      return 4
    default:
      return 24
  }
}
