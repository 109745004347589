/**
 * 系统缓存
 *
 *  2021-04-22  wuzhipeng
 */
const state = {
  caches:{}
}


const mutations = {
  CHANGE_SETTING: (state, { key, value }) => {
    if (state.hasOwnProperty(key)) {
      state[key] = value
    }
  },
  SET_KEY: (state, { key, value,type }) => {
    /*if(type==undefined || type===''){
      type='def';
    }
    let realKey=type+'#####'+key*/
    let realKey=key
    state.caches[realKey]=value
  },
}

const actions = {
  changeSetting({ commit }, data) {
    commit('CHANGE_SETTING', data)
  },
  setKey({ commit },data) {
    commit('SET_KEY',data)
  },
  getKey({ commit },data) {
    console.log("getKey",data)
    return '111'
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
