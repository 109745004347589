// 处理状态数据
import {
  LINK_LIST,
  CHART_STATUS_OBJ
} from '@/utils/institution/home/data'
import { DEEP_CLONE } from '@/utils/constants'
/**
 * 
 * @param {Object} chartStatus 接口返回的对象
 * @param {*} commit 
 */
function statusHandle(chartStatus, commit) {
  let status = DEEP_CLONE(chartStatus)
  let statusObj = DEEP_CLONE(CHART_STATUS_OBJ)
  let chartStatusObj = Object.assign(statusObj, status)
  const record = chartStatusObj.evalStatusCountMap // 报告 // 1---报告生成中数量 2--已发布的数量
  const task = chartStatusObj.matchStatusCountMap // 任务 // 1:已上传 2:待处理 3:待匹配 4:匹配完成
  // 待处理和待匹配同时只存在一个，当平台点击预处理之后待处理状态变为待匹配。
  if (record[2] > 0) {
    if (task[4] > 0) {
      // 存在1个或以上匹配完成任务，且有1个或多个已发布的治理报告 --> 显示雷达图,并且显示
      chartStatusObj.status = 4
      if (task[1] === 0 && task[2] === 0 && task[3] === 0) {
        chartStatusObj.link = null
      }
    } else {
      chartStatusObj.status = null
    }
  } else {
    if (task[2] + task[3] > 0) {
      // 存在待处理/待匹配任务 --> 查看当前任务详情
      chartStatusObj.status = 1
    } else if (task[4] > 0 && record[1] === 0 && record[2] === 0) {
      // 存在1个或以上匹配完成任务，但没有一份治理报告  --> 还没有治理报告，立即申请！
      chartStatusObj.status = 2
    } else if (task[4] > 0 && record[1] > 0 && record[2] === 0) {
      // 存在1个或以上匹配完成任务，且有只有生成中的治理报告 -->治理报告制作中，敬请等待
      chartStatusObj.status = 3
    } else {
      chartStatusObj.status = null
    }
  }
  if (chartStatusObj.link !== null && chartStatusObj.link !== undefined && chartStatusObj.status) {
    chartStatusObj.link = LINK_LIST[chartStatusObj.status - 1].text
  }
  
  // 当有治理报告，但是没有待处理和处理中的任务时，不显示文字链接

  commit('SET_INSTI_HOME_DATA', chartStatusObj)
  for (let key in record) {
    if (record[key] > 0) {
      commit('SET_INSTI_DEPT', { hasData: true })
      break
    } else {
      commit('SET_INSTI_DEPT', { hasData: false })
    }
  }
  for (let key in task) {
    if (task[key] > 0) {
      commit('SET_INSTI_DEPT', { hasData: true })
      break
    } else {
      commit('SET_INSTI_DEPT', { hasData: false })
    }
  }
  // return chartStatusObj
}
export { statusHandle }
