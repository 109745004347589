// 机构管理
import request from '@/modules/crm/utils/request'

//省份列表
export function provinceListApi() {
  return request({
    url: '/crm/regionDict/provinceList',
    method: 'post'
  })
}

//城市列表
export function cityList(provinceCode) {
  return request({
    url: '/crm/regionDict/cityList/' + provinceCode,
    method: 'post'
  })
}

//机构信息列表
export function orgListApi(data) {
  return request({
    url: '/crm/crmOrg/orgList',
    method: 'post',
    data: data
  })
}

// 行政区域字典树形结构
export function provinceCityTree() {
  return request({
    url: '/crm/regionDict/provinceCityTree',
    method: 'post'
  })
}

// 机构列表查询
export function searchOrgList(data) {
  return request({
    url: 'crm/crmOrg/searchOrgList',
    method: 'post',
    data: data
  })
}
//机构:删除
export function delOrgApi(orgId) {
  return request({
    url: '/crm/crmOrg/delOrg/' + orgId,
    method: 'post'
  })
}
// 机构联系人列表查询
export function searchContactsList(data) {
  return request({
    url: 'crm/crmOrg/searchContactsList',
    method: 'post',
    data: data
  })
}

// 机构:新增
export function addOrgApi(data) {
  return request({
    url: '/crm/crmOrg/addOrg',
    method: 'post',
    data: data
  })
}

// 机构详细信息
export function orgInfoApi(orgId) {
  return request({
    url: '/crm/crmOrg/orgInfo/' + orgId,
    method: 'post'
  })
}
// 机构关联合同列表
export function contractListApi(data) {
  return request({
    url: '/crm/crmOrg/contractList',
    method: 'post',
    data
  })
}
// 机构修改
export function editOrgApi(data) {
  return request({
    url: '/crm/crmOrg/editOrg',
    method: 'POST',
    data
  })
}
// 获取部门+职务树形结构
export function getPostTreeApi(orgType) {
  return request({
    url: '/crm/crmOrg/deptAndPostTree/' + orgType,
    method: 'POST'
  })
}
// 校验机构的统一社会信用代码
export function checkUscCodeApi(data) {
  return request({
    url: '/crm/crmOrg/checkUscCode',
    method: 'POST',
    data
  })
}
// /crm/crmOrg/checkOrgRelContract
export function checkOrgRelContractApi(orgId) {
  return request({
    url: '/crm/crmOrg/checkOrgRelContract/'+ orgId,
    method: 'POST'
  })
}

// 上传并批量导入机构文件
export function uploadAndImportCrmOrgApi(data) {
  return request({
    url: '/crm/crmOrg/uploadAndImportCrmOrg',
    method: 'post',
    data: data,
    timeout: 30 * 60 * 1000,
    returnError: true
  })
}
// 生成机构导入报告
export function createImportOrgReporApi(batchNum) {
  return request({
    url: '/crm/crmOrg/createImportOrgReport/' + batchNum,
    method: 'get',
    timeout: 30 * 60 * 1000,
    responseType: 'blob'
  })
}
