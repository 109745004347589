var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticStyle: { overflow: "hidden", width: "100%", height: "100%" } },
    [
      _c("iframe", {
        ref: "iframe",
        staticStyle: { width: "100%", height: "100%" },
        attrs: { frameborder: "0", src: _vm.iframeUrl },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }