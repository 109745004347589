import request from '@/utils/request'
export default {
    install: (Vue, options) => {
      /**
       * 列设置功能，初始化插入数据方法
       * @param {String/Number} id tableId
       * @param {Array} list 列设置的数据
       */
      Vue.prototype.insertDefaultData = (configKey, configClassify, list) => {
        if (!configKey) {
            return alert('列设置configKey不能为空！')
          }
          if (!configClassify) {
            return alert('列设置configClassify不能为空！')
          }
        if (!Array.isArray(list) || list.length <= 0) {
          return alert('列设置插入的数据不能为空！')
        }
        let obj = {}
        obj.list = list
        let obj2 = {}
        let str = JSON.stringify(obj)
        obj2.configValue = str
        obj2.configClassify = configClassify
        obj2.configKey = configKey
        return new Promise(function (resolve) {
          request({
            url: '/system/userConfig/saveOrUpdate',
            method: 'post',
            data: obj2
          }).then(function (res) {
            if (res.code === 200) {
              console.log('插入成功！')
              resolve()
            } else {
              console.log('插入失败！')
            }
          })
        })
      }

    }
  }