<template>
  <div v-if="Number(deptType) !== 1 && Number(deptType) !== 8" :class="classObj" class="app-wrapper" :style="{'--current-color': theme}">
    <div v-if="device==='mobile'&&sidebar.opened" class="drawer-bg" @click="handleClickOutside"/>
    <sidebar class="sidebar-container" :style="{ backgroundColor: sideTheme === 'theme-crm' ? variables.crmMenuBg : variables.menuLightBg }" />
    <div :class="{hasTagsView:needTagsView}" class="main-container">
      <div :class="{'fixed-header':fixedHeader}">
        <navbar />
      </div>
      <app-main />
      <div class="layout-bottom-bar">
        <!-- Copyright© 2021 Winning Health 版权所有 -->
        <div>
          <img src="../assets/images/icon.png" alt="" style="width: 20px;height: 20px;margin-top:10px;float: left;"/>
          <template>
            <span v-if="sideTheme === 'theme-crm'" style="padding-left:5px;">{{ crmOpennessConfig.sysVersion }}</span>
            <span v-else style="padding-left:5px;">{{ opennessConfig.webSystemLatestVersion }}</span>
          </template>

        </div>
        <!-- <div>
          <img src="../assets/images/beian-icon.png" alt="" style="width: 20px;height: 20px;margin-top:10px;float: left;"/>
          <a target="_blank" href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=31010602006620" style="display:inline-block;text-decoration:none;"><img src="" style="float:left;"/>
            <p style="color:#939393;">沪公网安备 31010602006620号</p>
          </a>
        </div> -->
        <!-- <div>
          <a href="https://beian.miit.gov.cn" target="_blank" style="padding: 0;" class="baNum">沪ICP备20018067号-2</a>
        </div> -->
        <div><span style="font-size: 18px;vertical-align: middle;">©</span>2022 上海卫心科技有限公司</div>
        <!-- <div>服务协议</div> -->
        <!-- <div>法律声明及隐私权政策</div> -->
        <template>
          <span v-if="sideTheme === 'theme-crm'" style="color: red;margin-left: 20px;font-size: 18px;">{{crmOpennessConfig.sysEnvName}}</span>
          <span v-else style="color: red;margin-left: 20px;font-size: 18px;">{{sysEnvMsg}}</span>
        </template>

      </div>
      <right-panel v-if="showSettings">
        <settings />
      </right-panel>
    </div>
  </div>
  <!--医疗机构-->
  <el-container v-else-if="Number(deptType) === 1" :class="instiContainerClass">
    <el-header :class="instiHeaderClass">
      <insti-header />
    </el-header>
    <el-main :class="instiMainClass">
      <insti-main />
    </el-main>
  </el-container>
  <!--合理用药--klib下载-->
  <el-container v-else-if="Number(deptType) === 8" class="klib-container layout-container">
    <el-header :class="instiHeaderClass">
      <klib-header />
    </el-header>
    <el-main :class="instiMainClass" class="klib-main-container">
      <insti-main />
    </el-main>
  </el-container>
  <!-- <el-container :class="classObj" class="app-wrapper layout-container" direction="horizontal" :style="{'--current-color': theme}" style="flex-direction: row;">
    <div v-if="device==='mobile'&&sidebar.opened" class="drawer-bg" @click="handleClickOutside"/>
    <el-aside style="width: auto;height: 100%;padding: 0;">
      <sidebar class="menu-sidebar-container" :style="{ backgroundColor: sideTheme === 'theme-dark' ? variables.menuBg : variables.menuLightBg }" />
    </el-aside>
    <el-main :class="{hasTagsView:needTagsView}" class="main-container" style="padding: 0;margin: 0;">
      <el-container class="layout-container">
        <el-header style="height: 52px;padding: 0;"><navbar /></el-header>
        <el-main style="padding: 0;"><app-main /></el-main>
        <el-footer style="height: 40px;padding: 0;color: #999;background-color: #EBEFF7;text-align: center;line-height: 40px;"><div>Copyright© 2021 Winning Health 版权所有</div></el-footer>
      </el-container>
    </el-main>
  </el-container> -->
</template>

<script>
import RightPanel from '@/components/RightPanel'
import { AppMain, Navbar, Settings, Sidebar, TagsView } from './components'
import ResizeMixin from './mixin/ResizeHandler'
import InstiMain from './institutionComponents/AppMain'
import InstiHeader from './institutionComponents/Header'
import KlibHeader from './rationalDrugUse/klibComponents/KlibHeader.vue'
import { mapState, mapGetters } from 'vuex'
import variables from '@/assets/styles/variables.scss'
import { getConfigKey,opennessConfigList } from '@/api/system/config'
import { crmOpennessConfigListApi } from '@/modules/crm/api/system/config'

export default {
  name: 'Layout',
  components: {
    AppMain,
    Navbar,
    RightPanel,
    Settings,
    Sidebar,
    TagsView,
    InstiHeader,
    InstiMain,
    KlibHeader
  },
  mixins: [ResizeMixin],
  data() {
    return {
      /*系统开发配置信息*/
      opennessConfig:{
        /*安卓app下载二维码base64*/
        androidAppDownloadQrCode:"",
        webSystemLatestVersion: '' // pc版本号
      },
      sysEnvMsg:"",
      instiHeaderClass: 'insti-layout-header box-shadow',
      type: 1, // 0-个人、1-医疗机构
      // 医疗机构登录之后，分为一次都没上传数据和上传过数据，两种情况显示的页面不同
      //crm系统的配置
      crmOpennessConfig:{

      }
    }
  },
  computed: {
    ...mapState({
      theme: state => state.settings.theme,
      sideTheme: state => state.settings.sideTheme,
      sidebar: state => state.app.sidebar,
      device: state => state.app.device,
      showSettings: state => state.settings.showSettings,
      needTagsView: state => state.settings.tagsView,
      fixedHeader: state => state.settings.fixedHeader,
      deptType: state => state.user.dept.deptType
    }),
    ...mapGetters(['instiDept']),
    classObj() {
      return {
        hideSidebar: !this.sidebar.opened,
        openSidebar: this.sidebar.opened,
        withoutAnimation: this.sidebar.withoutAnimation,
        mobile: this.device === 'mobile'
      }
    },
    variables() {
      return variables;
    },
    instiMainClass() {
      if (this.$store.getters.instiDept.hasData) {
        return 'insti-layout-main'
      } else {
        return 'insti-layout-nodata-main'
      }
    },
    instiContainerClass() {
      if (this.$store.getters.instiDept.hasData) {
        return 'layout-container insti-layout-container'
      } else {
        return 'layout-container insti-layout-container insti-layout-nodata-container'
      }
    }
  },
  watch: {
    $route(to, from) {
      // console.log('layout/index.vue')
      this.instiHeaderClassHandle(to)
    },
    deptType(newVal, oldVal) {
      // console.log(newVal)
    }
  },
  created() {
    // console.log('layout index')
    this.instiHeaderClassHandle()
    this.getEnvMsg()
    this.getOpennessConfig()
    if(this.sideTheme === 'theme-crm'){
      //加载crm系统的配置
      crmOpennessConfigListApi().then(res=>{
        this.crmOpennessConfig=res.data
      })
    }
  },
  activated() {
    // console.log(345)
  },
  methods: {
    /*获取系统开放配置信息*/
    getOpennessConfig(){
      opennessConfigList().then(res=>{
        this.opennessConfig=res.data
      })
    },
    handleClickOutside() {
      this.$store.dispatch('app/closeSideBar', { withoutAnimation: false })
    },
    instiHeaderClassHandle(to) {
      const path = this.$route.path
      // 当是医疗机构且没有数据的时候的首页的header样式不同
      if (path === '' || path === '/index') {
        if (this.$store.getters.instiDept.hasData) {
          this.instiHeaderClass = 'insti-layout-header box-shadow'
        } else {
          this.instiHeaderClass = 'insti-layout-header'
        }
      } else {
        this.instiHeaderClass = 'insti-layout-header box-shadow'
      }
      // if (this.$route.path === '/index') {
      //   this.instiHeaderClass = 'insti-layout-header'
      // }
    },
    /*获取当前环境信息，显示在页面底部*/
    getEnvMsg(){
      getConfigKey("sys_env_msg").then(res=>{
        if(res.msg){
          this.sysEnvMsg=res.msg
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
  @import "~@/assets/styles/mixin.scss";
  @import "~@/assets/styles/variables.scss";
  .baNum:hover{
    text-decoration:underline;
  }
  .app-wrapper {
    @include clearfix;
    position: relative;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    &.mobile.openSidebar {
      position: fixed;
      top: 0;
    }
  }

  .drawer-bg {
    background: #000;
    opacity: 0.3;
    width: 100%;
    top: 0;
    height: 100%;
    position: absolute;
    z-index: 999;
  }

  .fixed-header {
    position: fixed;
    top: 0;
    right: 0;
    z-index: 9;
    width: calc(100% - #{$sideBarWidth});
    transition: width 0.28s;
  }

  .hideSidebar .fixed-header {
    width: calc(100% - 54px)
  }

  .mobile .fixed-header {
    width: 100%;
  }
  .layout-bottom-bar{
    width: 100%;
    height: 40px;
    background: #EBEFF7;
    position: fixed;
    bottom: 0;
    z-index: 200;
    font-size: 12px;
    line-height: 40px;
    font-family: PingFang SC;
    font-weight: 400;
    color: #999999;
    text-align: center;
    padding-right: 200px;

    line-height: 40px;
    width: 100%;
    /* background: #0F174D;
    opacity: 0.8; */
    display: flex;
    justify-content: center;
    bottom: 0;
    text-align: center;
    /* color: #fff; */
    font-family: Arial;
    font-size: 14px;
    letter-spacing: 1px;
    div {
      margin-right: 15px;
      &:last-child {
        margin-right: 0;
      }
    }
  }
  /**医疗机构**/
  .insti-layout-container {
    /* background-color: #FCFCFC; */
    &.insti-layout-nodata-container {
      /* background-color: #fff;
      background-image: url('../assets/images/institution/bj.png');
      background-repeat: no-repeat;
      background-position: center center;
      background-size: cover; */
    }
    /* background: rgba(220, 220, 220, 0.3); */
    background-color: #FCFCFC;
    .box-shadow {
      box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.2);
    }
    .insti-layout-header {
      background: #FFFFFF;
      padding: 0;
      height: 56px !important;
    }
    .insti-layout-main {
      // padding: 12px 20px 12px;
      padding: 0 !important;
      #dmU, .el-container {
        background-color: inherit;
      }
    }
    .insti-layout-nodata-main {
      padding: 12px 20px 12px;
      #dmU, .el-container {
        background-color: inherit;
      }
    }
  }
  .klib-main-container {
    /* background-color: #F3F4F7; */
    background-image: linear-gradient(-30deg, #616BF2, #6F42EB);
    position: relative;
  }
  @import '~@/assets/styles/institution/header.scss';
  @import '~@/assets/styles/rationalDrugUse/header.scss';
</style>
