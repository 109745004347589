<!--  快捷分类维护 -->
<template>
  <div id="contextmenu" class="contextmenu">
    <el-dialog title="分类维护" width="70%"
               :visible.sync="dialogVisible"
               :close-on-click-modal="false"
    >
      <el-table
        border
        stripe
        :header-cell-style="{'text-align':'center'}"
        :cell-style="{'text-align':'center'}"
        :data="drugCategoryTableData"
      >
        <el-table-column type="index" width="50"></el-table-column>
        <el-table-column property="cas" label="分类信息" min-width="100">
          <template slot-scope="scope">
            <el-breadcrumb separator-class="el-icon-arrow-right">
              <el-breadcrumb-item v-for="item in scope.row.categoryList">
                {{ item.name }}
              </el-breadcrumb-item>
            </el-breadcrumb>
          </template>
        </el-table-column>
        <el-table-column label="操作" width="100">
          <template slot-scope="scope">
            <el-button @click="delDrugCategory(scope.row.categoryId)" type="text" style="color: #FF3333;"
                       v-show="dataType==scope.row.dataType"
                       size="small"
            >删除
            </el-button>
          </template>
        </el-table-column>
      </el-table>
    </el-dialog>

  </div>
</template>

<script>
import { getDrugCategoryTree ,delMapping} from '@/api/dkm/drugCategoryTreeList'
export default {
  name: 'index',
  data() {
    return {
      dialogVisible: false,
      dataType:undefined,
      dataId:undefined,
      drugCategoryTableData: []
    }
  },
  methods: {
    init() {

    },
    // 获取分类数据
    refreshDrugCategoryTree(dataId,dataType){
      this.dataId=dataId?dataId:this.dataId
      this.dataType=dataType?dataType:this.dataType
      getDrugCategoryTree(this.dataType,this.dataId).then(res=>{
        this.drugCategoryTableData=res.data
        this.drugCategoryTableData.forEach(item=>{
          let categoryList=[]
          let tempItem=item
          let categoryId=tempItem.id
          categoryList.push({name:tempItem.name,icon:tempItem.icon})
          while (tempItem.hasNext){
            tempItem=tempItem.children[0]
            categoryList.push({name:tempItem.name,icon:tempItem.icon})
            categoryId=tempItem.id
          }
          item.categoryList=categoryList
          item.categoryId=categoryId
        })
        this.dialogVisible=true
      })
    },
    // 删除分类数据
    delDrugCategory(categoryId){
      let that = this
      this.$confirm('确认要删除分类?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function() {
        delMapping(that.dataType,that.dataId,categoryId).then(res=>{
          that.refreshDrugCategoryTree()
          that.msgSuccess('删除成功')
        })
      })
    },

  }
}
</script>

<style scoped>

</style>
