<template>
  <section class="app-main">
    <transition name="fade-transform" mode="out-in">
      <keep-alive :include="cachedViews" :exclude="noCachedViews">
        <router-view v-if="!$route.meta.url" :key="key" />
      </keep-alive>
    </transition>
    <iframe-toggle v-show="$route.meta.url"/>
  </section>
</template>

<script>
import iframeToggle from "./IframeToggle/index"
import AcrossUtil from '@/utils/acrossUtil'

export default {
  name: 'AppMain',
  components: { iframeToggle },
  created() {
    let $router = this.$router
    window.$router = {}

    // 关闭page
    window.$router.closePage=function(path){
      setTimeout(() => {
        AcrossUtil.$emit('closeView',path);
      }, 100);
    }


    // router 路由参数 reload是否重载 默认为false
    window.$router.push = function (router,reload) {
      //console.log("window.$router.push",router, reload)
      //重新加载页面
      if(reload){
        let filterRouter=''
        if(router.indexOf('?')>-1){
          //路由url中有参数，需要过滤掉
           filterRouter=router.substring(0,router.indexOf('?'))
        }else{
          filterRouter=router
        }
        let dom=document.getElementsByName(filterRouter)
        if(dom && dom.length>0){
          let pageIframes= dom[0].getElementsByTagName('iframe')
          if(pageIframes && pageIframes.length>0)
            pageIframes[0].contentWindow.location.reload(true);
        }
      }
      //console.log("$router.push(router)",router)

      $router.push(router)
    }
  },
  computed: {

    cachedViews() {
      return this.$store.state.tagsView.cachedViews
    },
    noCachedViews() {
      return this.$store.state.tagsView.noCachedViews
    },
    key() {
      return this.$route.path
    }
  },
  methods: {
    console(msg) {
      console.log("console:", msg)
    }
  }

}
</script>

<style lang="scss" scoped>
.app-main {
  /* 50= navbar  50  */
  //min-height: calc(100vh);
  min-height: 100%;
  height: calc(100% - 90px);
  display: flex;
  flex-direction: column;
  /* display: grid; */
  width: 100%;
  position: relative;
  overflow: hidden;
  padding: 50px 0 40px;
  background: #ffffff;
}

.fixed-header + .app-main {
  /* padding-top: 50px; */
}

.hasTagsView {

  .bottom-bar {
    width: 100%;
    height: 30px;
    background: #EBEFF7;
    position: fixed;
    bottom: 0;
    z-index: 200;
    font-size: 12px;
    line-height: 30px;
    font-family: PingFang SC;
    font-weight: 400;
    color: #999999;
    text-align: center;
    padding-right: 200px;
  }
}
</style>

<style lang="scss">
// fix css style bug in open el-dialog
.el-popup-parent--hidden {
  .fixed-header {
    padding-right: 15px;
  }
}
</style>
