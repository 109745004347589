<template>
  <div class="" style="overflow: hidden;width: 100%;height: 100%">
    <iframe
      ref="iframe"
      frameborder="0"
      :src="iframeUrl"
      style="width: 100%;height: 100%"
    >
    </iframe>


  </div>
</template>

<script>


export default {
  name: 'List',
  components: {},
  props: {
    iframeUrl: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      // 遮罩层
      loading: true,



    }
  },
  created() {
    //console.log( this.$route)
    //console.log( this.$route.path)
    //console.log( this.$route.params)
    //this.iframeUrl="http://localhost:1024/"+this.$route.meta.url
    //this.iframeUrl=this.$route.meta.url
  },
  activated() {

  },
  mounted() {

  },
  methods: {
  }
}
</script>

<style lang="scss" scoped>


</style>


<style>


</style>
