var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading",
        },
      ],
      staticStyle: { overflow: "hidden", width: "100%", height: "100%" },
      attrs: {
        "element-loading-background": "#ffffff",
        "element-loading-text": "正在加载页面，请稍候！",
      },
    },
    [
      _vm.iframeShow
        ? _c("iframe", {
            staticStyle: { width: "100%", height: "100%" },
            attrs: { id: _vm.iframeId, src: _vm.src, frameborder: "no" },
          })
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }