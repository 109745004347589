import EdtColumnSettingsVue from '../components/EdtColumnSettings/index.vue'
import request from '@/utils/request'
// import { praseStrEmpty } from '@/utils/common'
// 更新用户个人配置信息
export function UCsaveOrUpdate(data) {
  return request({
    url: '/system/userConfig/saveOrUpdate',
    method: 'post',
    data: data
  })
}

// 根据configKey获取用户个人配置信息
export function UCgetConfig(data) {
  return request({
    url: '/system/userConfig/getConfig',
    method: 'get',
    params: data
  })
}

const edtColumnSettings = {}
// vue的install方法，用于定义vue插件
edtColumnSettings.install = function (Vue, options) {
  const ColumnSettingsInstance = Vue.extend(EdtColumnSettingsVue)
  let currentColSettings
  const initInstance = () => {
    // 实例化vue实例
    currentColSettings = new ColumnSettingsInstance()
    let colSettingsEl = currentColSettings.$mount().$el
    document.body.appendChild(colSettingsEl)
  }
  // 在Vue的原型上添加实例方法，以全局调用
  Vue.prototype.$EdtColumnSettings = {
    showColSettings(configKey, configClassify, list, func) {
      // configKey: 配置键名
      // configClassify: 配置分类
      // updateId: 修改的列设置数据对应的id
      // func1: 保存修改页面，需要执行的操作
      // func2: 恢复默认设置的函数
      console.log(configKey)
      if (!configKey) {
        return alert('请传入正确的configKey！')
      }
      if (!configClassify) {
        return alert('请传入正确的configClassify！')
      }
      // if (!updateId) {
      //   return alert('请传入正确的updateId！')
      // }
      if (!list) {
        return alert('请传入默认的列设置数据！')
      }
      if (!currentColSettings) {
        initInstance()
      }
      // currentColSettings.updateId = updateId
      currentColSettings.configKey = configKey
      currentColSettings.configClassify = configClassify
      currentColSettings.getHeader = func
      currentColSettings.defaultList = list
      currentColSettings.showColSettings()
    }
  }
}
export default edtColumnSettings
