var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "navbar" }, [
    _vm._m(0),
    _c(
      "div",
      { staticClass: "header-right" },
      [
        _c("insti-menu", {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.isShowMenu || _vm.hasData,
              expression: "isShowMenu || hasData",
            },
          ],
          on: { routerEnter: _vm.onRouterEnter },
        }),
        _c(
          "div",
          { staticClass: "right-menu" },
          [
            _vm.device !== "mobile" ? void 0 : _vm._e(),
            _c(
              "el-dropdown",
              {
                staticClass: "avatar-container right-menu-item hover-effect",
                attrs: { trigger: "click" },
              },
              [
                _c(
                  "div",
                  {
                    staticClass: "avatar-wrapper",
                    staticStyle: { "text-align": "right" },
                  },
                  [
                    _c("i", { staticClass: "el-icon-s-custom userIcon" }),
                    _c("span", { staticClass: "userName" }, [
                      _vm._v(_vm._s(_vm.name)),
                    ]),
                    _c("i", { staticClass: "el-icon-caret-bottom" }),
                  ]
                ),
                _c(
                  "el-dropdown-menu",
                  { attrs: { slot: "dropdown" }, slot: "dropdown" },
                  [
                    _c(
                      "router-link",
                      { attrs: { to: "/user/profile" } },
                      [_c("el-dropdown-item", [_vm._v("个人中心")])],
                      1
                    ),
                    _c(
                      "el-dropdown-item",
                      {
                        attrs: { divided: "" },
                        nativeOn: {
                          click: function ($event) {
                            return _vm.logout($event)
                          },
                        },
                      },
                      [_c("span", [_vm._v("退出登录")])]
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          2
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _c("img", {
        attrs: { src: require("../../assets/images/logo-pdBox.png") },
      }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }