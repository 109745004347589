<!--医疗机构登录显示的主内容-->refreshSelectedTag
<template>
  <div style="width: 100%;height: 100%;">
    <keep-alive>
      <router-view v-if="!$route.meta.url" :key="key" />
    </keep-alive>
    <!-- <keep-alive> -->
      <iframe-toggle v-show="$route.meta.url"/>
    <!-- </keep-alive> -->
  </div>

</template>
<script>
import AcrossUtil from '@/utils/acrossUtil'
import iframeToggle from "@/layout/components/IframeToggle/index"
export default {
  name: 'InstiAppMain',
  components: { iframeToggle },
  created() {
    console.log(this.$route)
    console.log(this.$route.meta.url)
    //初始化直接访问的是iframe页面，需要手动处理下
    let nowRoute = this.$router.currentRoute
    if (this.iframeViews.length== 0 && nowRoute.meta && nowRoute.meta.url) {
      this.$store.dispatch('tagsView/addIframeView', nowRoute)
    }

    let $router = this.$router
    window.$router = {}

    // 关闭page
    window.$router.closePage=function(path){
      setTimeout(() => {
        AcrossUtil.$emit('closeView',path);
      }, 100);
    }
    // router 路由参数 reload是否重载 默认为false
    window.$router.push = function (router,reload) {
      //重新加载页面
      if(reload){
        let filterRouter=''
        if(router.indexOf('?')>-1){
          //路由url中有参数，需要过滤掉
          filterRouter=router.substring(0,router.indexOf('?'))
        }else{
          filterRouter=router
        }
        let dom=document.getElementsByName(filterRouter)
        if(dom && dom.length>0){
          let pageIframes= dom[0].getElementsByTagName('iframe')
          if(pageIframes && pageIframes.length>0)
            pageIframes[0].contentWindow.location.reload(true);
        }
      }

      $router.push(router)
    }

  },
  computed: {
    cachedViews() {
      // console.log(this.$store.state.tagsView.cachedViews)
      return this.$store.state.tagsView.cachedViews
    },
    noCachedViews() {
      return this.$store.state.tagsView.noCachedViews
    },
    key() {
      return this.$route.path
    },
    iframeViews() {
      return this.$store.state.tagsView.iframeViews
    }
  },
  watch: {
    $route(to, from) {
      //路由切换
      if (to.meta.url) {
        this.$store.dispatch('tagsView/addIframeView', to)
      }
    }
  },
  methods: {
    console(msg) {
      console.log("console:", msg)
    }

  }

}
</script>

<style lang="scss" scoped>

</style>
