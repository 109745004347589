<template>
  <div
       style="overflow: hidden;width: 100%;height: 100%"
       v-loading="loading"
       element-loading-background="#ffffff"
       element-loading-text="正在加载页面，请稍候！">
    <iframe
      v-if="iframeShow"
      :id="iframeId"
      style="width: 100%; height: 100%"
      :src="src"
      frameborder="no"
    ></iframe>
  </div>
</template>

<script>
export default {
  props: {
    src: {
      type: String,
      default: "/"
    },
    iframeId: {
      type: String
    }
  },
  created() {
    //console.log("iframe--created",this.src,this.iframeId)
  },
  data() {
    return {
      loading: false,
      iframeShow:true,
      height: document.documentElement.clientHeight - 94.5 + "px;"
    };
  },
  mounted() {
    var _this = this;
    const iframeId = ("#" + this.iframeId).replace(/\//g, "\\/");
    const iframe = document.querySelector(iframeId);
    // iframe页面loading控制
    if (iframe.attachEvent) {
      this.loading = true;
      iframe.attachEvent("onload", function () {
        _this.loading = false;
        _this.iframeReload(iframe)
      });
    } else {
      this.loading = true;
      iframe.onload = function () {
        _this.loading = false;
        _this.iframeReload(iframe)

      };
    }
  },
  methods:{
    //解决偶尔会出现的打开了iframe页面，但是里面确是空白的情况
    iframeReload(iframe){
      var _this = this
      setTimeout(() => {
        var pageDiv = iframe.contentWindow.document.querySelector("#app div")
        if(!pageDiv){
          _this.iframeShow=false
          _this.$nextTick(() => {
            _this.iframeShow=true
            console.log("iframe重新加载")
          })

        }


      }, 500);


    }
  }
};
</script>
