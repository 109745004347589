import axios from 'axios'
import {Notification, MessageBox, Message, Loading} from 'element-ui'
import store from '@/modules/crm/store/index'
import {getToken,} from '@/utils/auth'
import errorCode from '@/utils/errorCode'
import Cookies from "js-cookie";
import qs from 'qs'

axios.defaults.headers['Content-Type'] = 'application/json;charset=utf-8'
// 创建axios实例
const timeout = 60000
const service = axios.create({
  // axios中请求配置有baseURL选项，表示请求URL公共部分
  baseURL: process.env.VUE_APP_BASE_API,
  // 超时
  timeout
})
//在window对象上绑定当前环境，普通js文件可以使用window.VUE_APP_BASE_API获取到当前环境
window.VUE_APP_BASE_API = process.env.VUE_APP_BASE_API
window.axios = axios
let requestConfig = null
// request拦截器
service.interceptors.request.use(config => {
  // 是否需要设置 token
  requestConfig = config
  const isToken = (config.headers || {}).isToken === false
  if (getToken() && !isToken) {
    config.headers['Authorization'] = 'Bearer ' + getToken() // 让每个请求携带自定义token 请根据实际情况自行修改
  }
  config.paramsSerializer = function (params) {
    return qs.stringify(params, {
      indices: false
    })
  }
  return config
}, error => {
  console.log(error)
  Promise.reject(error)
})

// 响应拦截器
service.interceptors.response.use(res => {


    // 未设置状态码则默认成功状态

    const code = res.data.code || 200;
    // 获取错误信息
    const msg = errorCode[code] || res.data.msg || errorCode['default']
    if (code === 401) {
     console.log("需要登录",store)
      store.dispatch('SetNeedReLogin',true)
      //store.state.user.needReLogin=true
    } else if (code === 500) {
      if (requestConfig.returnError) {
        return res.data
      }
      if (!requestConfig.noGloablErrMsg) {
        Message({
          message: msg,
          type: 'error'
        })
      }
      return Promise.reject(new Error(msg))
    } else if (code !== 200) {
      if (!requestConfig.insideError) {
        // 如果接口处理报错需要在页面中写代码，则需要在接口配置里加上insideError: true
        // Notification.error({
        //   title: msg
        // })
        Message.error(msg)
      }
      return res.data
    } else if (!res.data.code && res.config.responseType === 'blob' && res.config.resopnseReturn) {
      // 场景有：下载文件，可以通过res.headers中获取后台提供的文件名
      return res
    } else {
      return res.data
    }
    return res.data
  },
  error => {

    console.log('err' + error)
    let {message} = error;
    if (message == "Network Error") {
      message = "后端接口连接异常";
    } else if (message.includes("timeout")) {
      message = "系统接口请求超时";
    } else if (message.includes("Request failed with status code")) {
      message = "系统接口" + message.substr(message.length - 3) + "异常";
    }
    Message({
      message: message,
      type: 'error',
      duration: 5 * 1000
    })
    return Promise.reject(error)
  }
)

export default service
