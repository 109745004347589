var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("span", [
    _vm.iconType == "base64"
      ? _c(
          "svg",
          {
            staticStyle: {
              width: "14px",
              height: "14px",
              "vertical-align": "middle",
              "margin-right": "5px",
            },
            style: { width: _vm.size + "px", height: _vm.size + "px" },
          },
          [
            _c("image", {
              staticStyle: { width: "100%", height: "100%" },
              attrs: { "xlink:href": _vm.iconCode },
            }),
          ]
        )
      : _vm._e(),
    _vm.iconType == "css" && _vm.cssIcon.codeType
      ? _c(
          "span",
          { staticClass: "code-icon" },
          [
            _c(
              "el-tag",
              {
                style: { color: _vm.cssIcon.color },
                attrs: {
                  effect: "plain",
                  size: "small",
                  type: _vm.cssIcon.type,
                },
              },
              [_vm._v(_vm._s(_vm.cssIcon.text))]
            ),
          ],
          1
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }