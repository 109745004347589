var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "navbar" },
    [
      _c(
        "el-row",
        [
          _c(
            "el-col",
            { attrs: { span: 1 } },
            [
              _c("hamburger", {
                staticClass: "hamburger-container",
                attrs: {
                  id: "hamburger-container",
                  "is-active": _vm.sidebar.opened,
                },
                on: { toggleClick: _vm.toggleSideBar },
              }),
            ],
            1
          ),
          _c("el-col", { attrs: { span: 20 } }, [_c("tags-view")], 1),
          _c("el-col", { attrs: { span: 3 } }, [
            _c(
              "div",
              { staticClass: "right-menu" },
              [
                _vm.device !== "mobile" ? void 0 : _vm._e(),
                _c(
                  "el-dropdown",
                  {
                    staticClass:
                      "avatar-container right-menu-item hover-effect",
                    attrs: { trigger: "click" },
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "avatar-wrapper",
                        staticStyle: { width: "250px", "text-align": "right" },
                      },
                      [
                        _c("i", { staticClass: "el-icon-s-custom userIcon" }),
                        _c("span", { staticClass: "userName" }, [
                          _vm._v(
                            _vm._s(_vm.name) + "(" + _vm._s(_vm.deptName) + ")"
                          ),
                        ]),
                        _c("i", { staticClass: "el-icon-caret-bottom" }),
                      ]
                    ),
                    _c(
                      "el-dropdown-menu",
                      { attrs: { slot: "dropdown" }, slot: "dropdown" },
                      [
                        _c(
                          "router-link",
                          { attrs: { to: "/user/profile" } },
                          [_c("el-dropdown-item", [_vm._v("个人中心")])],
                          1
                        ),
                        _c(
                          "el-dropdown-item",
                          {
                            staticStyle: { display: "none" },
                            nativeOn: {
                              click: function ($event) {
                                _vm.setting = true
                              },
                            },
                          },
                          [_c("span", [_vm._v("布局设置")])]
                        ),
                        _c(
                          "el-dropdown-item",
                          {
                            attrs: { divided: "" },
                            nativeOn: {
                              click: function ($event) {
                                return _vm.logout($event)
                              },
                            },
                          },
                          [_c("span", [_vm._v("退出登录")])]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              2
            ),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }