import request from '@/modules/crm/utils/request'

// 登录方法
export function doLogin(data) {
  return request({
    url: '/crm/login/doLogin',
    method: 'post',
    data: data,
    loading: false
  })
}


// 获取用户详细信息
export function getInfoApi() {
  return request({
    url: '/crm/login/getInfo',
    method: 'get'
  })
}


// 退出方法
export function logout() {
  return request({
    url: '/logout',
    method: 'post'
  })
}


// 获取路由
export const getRouters = () => {
  return request({
    url: '/crm/sys/menu/getRouters',
    method: 'get'
  })
}
