var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { ref: "contextmenu", staticClass: "contextmenu" },
    _vm._l(_vm.action, function (item) {
      return _c(
        "span",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: item.name != "查看11",
              expression: "item.name!='查看11'",
            },
          ],
          staticClass: "contextmenu__item",
          style: item.style,
          on: {
            click: function ($event) {
              return _vm.handleTableAction(item.name, _vm.row, _vm.column)
            },
          },
        },
        [_vm._v(_vm._s(item.name) + " ")]
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }