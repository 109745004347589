<!--  表格右键菜单 参考 https://www.jianshu.com/p/02d42f689c58 -->
<template>
  <div ref="contextmenu" class="contextmenu">

      <span class="contextmenu__item" v-show="item.name!='查看11'"
          v-for="item in action"
          @click="handleTableAction(item.name,row,column)"
          :style="item.style"
      >{{ item.name }}
      </span>

  </div>
</template>

<script>
export default {
  name: 'index',
  props: {
    action: {
      type: Array
    }
  },
  data() {
    return {
      row:{},
      column:{}
    }
  },
  methods: {
    init(row, column, event) {
      this.row=row
      this.column=column
      // 设置菜单出现的位置
      // 具体显示位置根据自己需求进行调节

      const menu = this.$refs.contextmenu
      //右键菜单的宽高
      //console.log("右键菜单宽高", menu.clientWidth, menu.clientHeight)
      //浏览器可视区域的宽高
      //console.log("浏览器可视区域document.body", document.body.clientHeight, document.body.clientWidth)
      //鼠标点击位置的宽高
      //console.log(" 鼠标点击位置", event.clientY, event.clientX)

      // Y轴位置计算，防止菜单太靠底，根据可视高度调整菜单出现位置
      let yCha = document.body.clientHeight - event.clientY

      if (yCha < menu.clientHeight) {
        //console.log("菜单Y轴显示不全，需要调整位置", yCha)
        //菜单Y轴显示不全，需要调整位置
        menu.style.top = (event.clientY + yCha - menu.clientHeight - 90) + 'px'
      } else {
        menu.style.top = event.clientY - 100 + 'px'
      }

      // X轴位置计算
      let xCha = document.body.clientWidth - event.clientX
      //console.log("xCha",xCha)
      if (xCha < menu.clientWidth) {
        //console.log("菜单X轴显示不全，需要调整位置", xCha)
        //菜单Y轴显示不全，需要调整位置
        menu.style.left = (event.clientX- menu.clientWidth - 210) + 'px'
      } else {
        menu.style.left = event.clientX-190 + 'px'
      }
      //console.log("menu.style.left",menu.style.left,"event.clientX",event.clientX)

      document.addEventListener('click', this.foo) // 给整个document添加监听鼠标事件，点击任何位置执行foo方法

    },
    foo() {
      this.$emit('foo')
    },
    handleTableAction(actionName,row, column) {
      this.$emit('handleTableAction',actionName,row, column)
    }
  }
}
</script>

<style scoped>
.contextmenu__item {
  display: block;
  line-height: 34px;
  text-align: center;
}

.contextmenu__item:not(:last-child) {
  border-bottom: 1px solid rgba(64, 158, 255, .2);
}

.contextmenu {
  position: absolute;
  background-color: #ecf5ff;
  width: 150px;
  /*height: 106px;*/
  font-size: 16px;
  color: #409EFF;
  border-radius: 4px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  border: 1px solid rgba(64, 158, 255, .2);
  white-space: nowrap;
  z-index: 1000;
}

.contextmenu__item:hover {
  cursor: pointer;
  background: #2B60F8;
  border-color: #2B60F8;
  color: #fff;
}
</style>
