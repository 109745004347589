import router from './router'
import store from './store'
import { Message } from 'element-ui'
import NProgress from 'nprogress'
import 'nprogress/nprogress.css'
import { getToken } from '@/utils/auth'

NProgress.configure({ showSpinner: false })

const whiteList = [
  '/login',
  '/auth-redirect',
  '/bind',
  '/register',
  '/findPwd',
  '/serviceAgreement',
  '/privacyPolicy',
  '/ruleOfChildren',
  '/serviceIntro'
]

router.beforeEach((to, from, next) => {
  NProgress.start()
  //console.log('test token')
  //console.log(getToken())
  //console.log(to)
  if (getToken()) {
    /* has token*/
    if (to.path === '/login') {
      next({ path: '/' })
      NProgress.done()
    } else {
      if (store.getters.roles.length === 0) {
        // 判断当前用户是否已拉取完user_info信息
        store.dispatch('GetInfo').then(() => {
          store.dispatch('GenerateRoutes').then(accessRoutes => {
            // 根据roles权限生成可访问的路由表
            router.addRoutes(accessRoutes) // 动态添加可访问路由表
            // 判读是否是医疗机构
            next({ ...to, replace: true }) // hack方法 确保addRoutes已完成
            //console.log(router)
          })
        }).catch(err => {
            store.dispatch('LogOut').then(() => {
              Message.error(err)
              next({ path: '/' })
            })
          })
      } else {
        next()
      }
    }
  } else {
    // 没有token

    //处理访问吗地址是[http://127.0.0.1:1024/login?redirect=%2Findex#/index]这样的情况，
    //这种情况会导致获取url中的参数不正确
    let url=window.location.href
    //console.log("url",url)
    if(url.indexOf("redirect=")>0){
      url=url.substr(0,url.indexOf("redirect=")-1)
      //console.log("newUrl",url)
      history.pushState(null, null,url)
    }


    if (whiteList.indexOf(to.path) !== -1) {
      // 在免登录白名单，直接进入
      next()
    } else {
      // next(`/login?redirect=${to.fullPath}`) // 否则全部重定向到登录页
      // next('/login')
      // 如果目的路由是隐藏的菜单，则需要回退到父路由或直接跳转到登录页面
      if (to.meta.hidden) {
        next('/login')
      } else {
        next('/login')
        // next(`/login?redirect=${to.fullPath}`)
      }
      NProgress.done()
    }
  }
})

router.afterEach(() => {
  NProgress.done()
})
