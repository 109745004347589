/**
 * 缓存key常量
 *
 *  2022-06-15  wuzhipeng
 */

module.exports = {
  /**hello页面dept缓存*/
  HELLO_HOME_DEPT: 'hello_home_dept',
  /**list页面table缓存*/
  HELLO_LIST_TABLE: 'hello_list_table',
  ROUTE_PARAMS:'route_params',

}
