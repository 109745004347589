import { constantRoutes } from '@/router'
import { getRouters } from '@/modules/crm/api/login'
//import { getRouters } from '@/api/menu'
import Layout from '@/layout/index'
import ParentView from '@/components/ParentView';

const permission = {
  state: {
    routes: [],
    addRoutes: [],
    sidebarRouters: []
  },
  mutations: {
    SET_ROUTES: (state, routes) => {
      state.addRoutes = routes
      state.routes = constantRoutes.concat(routes)
    },
    SET_SIDEBAR_ROUTERS: (state, routers) => {
      state.sidebarRouters = constantRoutes.concat(routers)
    },
  },
  actions: {
    // 生成路由
    GenerateRoutes({ commit }) {
      return new Promise(resolve => {
        // 向后端请求路由数据
        getRouters().then(res => {
          handleRoutersData(res.data)
          const sdata = JSON.parse(JSON.stringify(res.data))
          const rdata = JSON.parse(JSON.stringify(res.data))
          const sidebarRoutes = filterAsyncRouter(sdata, false, false)
          const rewriteRoutes = filterAsyncRouter(rdata, false, true)
          rewriteRoutes.push({ path: '*', redirect: '/404', hidden: true })
          commit('SET_ROUTES', rewriteRoutes)
          commit('SET_SIDEBAR_ROUTERS', sidebarRoutes)
          // console.log(rewriteRoutes)
          resolve(rewriteRoutes)
        })
      })
    }
  }
}

// 获取到的路由预处理，主要是处理iframe相关的
function handleRoutersData(routerData){
  routerData.forEach(routerItem=>{
    //如果是外链，
    if(routerItem.isFrame=='0'){
      routerItem.meta.isFrame=routerItem.isFrame
      routerItem.meta.url=routerItem.component
      //iframe页面组件将component设置为空，路由就不会渲染了
      routerItem.component=undefined
    }
    if (routerItem.children != null && routerItem.children && routerItem.children.length) {
      handleRoutersData(routerItem.children)
    }
  })
}

// 遍历后台传来的路由字符串，转换为组件对象
function filterAsyncRouter(asyncRouterMap, lastRouter = false, type = false) {
  return asyncRouterMap.filter(route => {

    if (type && route.children) {
      route.children = filterChildren(route.children)
    }
    if (route.component) {
      // Layout ParentView 组件特殊处理
      if (route.component === 'Layout') {
        route.component = Layout
      } else if (route.component === 'ParentView') {
        route.component = ParentView
      } else {
        route.component = loadView(route.component, route)
        if (!lastRouter.alwaysShow) {
          route.alwaysShow = lastRouter.alwaysShow
          route.sourceType = 'noChildrenMenu' // 机构端的菜单，且没有子菜单
        } else {
          route.alwaysShow = undefined
        }
      }
    }
    if (route.children != null && route.children && route.children.length) {
      route.children = filterAsyncRouter(route.children, route, type)
      // route.isLeaf = false
    } else if (!route.children && route.sourceType === undefined){
      // route.isLeaf = true
      delete route['children']
      delete route['redirect']
    } else if (!route.children && route.sourceType === 'noChildrenMenu') {
      // 机构端的路由菜单
      route.alwaysShow = false // 区分是机构端的菜单
      route.sourceType = 'noChildrenMenu' // 机构端的菜单，且没有子菜单
      let mainPath = route.path.split('/')[1]
      let name = mainPath.slice(0,1).toUpperCase() + mainPath.slice(1)
      route.children = [
        {
          component: route.component,
          hidden: false,
          path: route.path,
          meta: route.meta,
          name
        }
      ]
      route.component = Layout
    }
    return true
  })
}

function filterChildren(childrenMap, lastRouter = false) {
  var children = []
  childrenMap.forEach((el, index) => {
    if (el.children && el.children.length) {
      if (el.component === 'ParentView') {
        el.children.forEach(c => {
          c.path = el.path + '/' + c.path
          if (c.children && c.children.length) {
            children = children.concat(filterChildren(c.children, c))
            return
          }
          children.push(c)
        })
        return
      }
    }
    if (lastRouter) {
      el.path = lastRouter.path + '/' + el.path
    }
    children = children.concat(el)
  })
  return children
}

export const loadView = (view, route) => { // 路由懒加载
  //console.log("loadView",view, route)
  try {
    return (resolve) => require([`@/modules/crm/view/${view}`], resolve)
  }catch (e) {
    return (resolve) => require([`@/views/${view}`], resolve)
  }
}

export default permission
