<!---->
<template>
  <div class="navbar">
    <div>
      <img src="../../assets/images/logo-pdBox.png" />
      <!-- <span class="header-text">PDBox—药数矩阵</span> -->
    </div>
    <div class="header-right">
      <insti-menu v-show="isShowMenu || hasData" @routerEnter="onRouterEnter"/>
      <div class="right-menu">
        <template v-if="device!=='mobile'">
        </template>
        <el-dropdown class="avatar-container right-menu-item hover-effect" trigger="click">
          <div class="avatar-wrapper" style="text-align:right;">
            <i class="el-icon-s-custom userIcon"></i>
            <span class="userName">{{ name }}</span>
            <i class="el-icon-caret-bottom" />
          </div>
          <el-dropdown-menu slot="dropdown">
            <router-link to="/user/profile">
              <el-dropdown-item>个人中心</el-dropdown-item>
            </router-link>
            <el-dropdown-item divided @click.native="logout">
              <span>退出登录</span>
            </el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
    </div>
  </div>
</template>
<script>
  import { mapGetters } from 'vuex'
  import Breadcrumb from '@/components/Breadcrumb'
  import Hamburger from '@/components/Hamburger'
  import Screenfull from '@/components/Screenfull'
  import SizeSelect from '@/components/SizeSelect'
  import Search from '@/components/HeaderSearch'
  import TagsView  from '../components/TagsView/index.vue'
  import InstiMenu from './Menu.vue'
  import Cookies from "js-cookie";
  import { pushRoute } from '@/utils/routerUtil'

  export default {
    components: {
      Breadcrumb,
      Hamburger,
      Screenfull,
      SizeSelect,
      Search,
      TagsView,
      InstiMenu
    },
    computed: {
      ...mapGetters([
        'sidebar',
        'sidebarRouters',
        'avatar',
        'device',
        'name',
        'deptName',
        'instiDept'
      ]),
      setting: {
        get() {
          return this.$store.state.settings.showSettings
        },
        set(val) {
          this.$store.dispatch('settings/changeSetting', {
            key: 'showSettings',
            value: val
          })
        }
      },
      hasData() {
        // 医疗机构判断其是否有数据
        return this.instiDept.hasData
      }
    },
    data() {
      return {
        activeIndex: '1',
        isShowMenu: false
      }
    },
    created() {
      // console.log(this.sidebar)
      // console.log(this.sidebarRouters)
      // console.log(this.instiDept)
      this.onRouterEnter(this.$route.path)
    },
    methods: {
      toggleSideBar() {
        this.$store.dispatch('app/toggleSideBar')
        //左侧菜单栏展开关闭时候，手动触发resize事件
        if(document.createEvent) {
          var event = document.createEvent("HTMLEvents");
          event.initEvent("resize", true, true);
          window.dispatchEvent(event);
        } else if(document.createEventObject) {
          window.fireEvent("onresize");
        }
      },
      async logout() {
        this.$confirm('确定注销并退出系统吗？', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.$store.dispatch('LogOut').then(() => {
            pushRoute({ path: '/login' })
          })
        })
      },
      onRouterEnter(data) {
        if ((data.path !== '' && data.path !== '/index') && data !== '/index') {
          this.isShowMenu = true
        } else {
          this.isShowMenu = false
        }
        // console.log(this.hasData)
        // console.log(this.isShowMenu)
      }
    }
  }
  </script>

  <style lang="scss" scoped>
  .navbar {
    display: flex;
    justify-content: space-between;
    height: 56px;
    align-items: center;
    padding: 0 20px;
    .header-right {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .userIcon {
        color: #8599C0;
        font-size: 20px;
        margin-right: 5px;
      }
    }
  }

  </style>
