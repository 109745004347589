import Vue from 'vue'
import { EL_COL_GRID, EL_COL_CONFIG, EL_COL_VCONFIG, DEEP_CLONE } from '@/utils/constants'

/**
 * 注册el-col专用全局指令： v-cspans，用于设置col在不同分辨率下每行的列数
 * 如果col不需要根据分辨率自适应则不需要设置
 * 参数： { xs: 列数, s:列数, m:列数, l:列数, x:列数 }
 * 参数对应分辨率：xs: <1000；s: >1000；m: >1054；l: >1470；x: >1920
 * 5个规格不需要全部设置，只设置需要变化的规格即可，如：{ x:4, l:3, m:2 }
 * 未设置规格会根据最大最小情况自动补全
 */
Vue.directive('cspan', {
  // 当元素插入到 DOM 时
  inserted: function (el, binding) {
    if (binding.value) {
      let valueConfig = binding.value
      let config = DEEP_CLONE(EL_COL_CONFIG)
      let order = []
      // config从小到大尺寸排列
      for (let v of config) {
        if (valueConfig[v.symbol]) {
          v.col = valueConfig[v.symbol]
          order.push(valueConfig[v.symbol])
        }
      }
      let className = ''
      // 再次遍历赋值其他区间值
      for (let v of config) {
        if (!valueConfig[v.symbol]) {
          v.col = order[0]
        } else {
          order.slice(0, 1)
        }
        // 设置自适应所需要的class
        className += v.class + EL_COL_GRID(v.col) + ' '
      }
      addClass([el], className)
    }
  }
})
Vue.directive('vspan', {
  // 当元素插入到 DOM 时
  inserted: function (el, binding) {
    if (binding.value) {
      let valueConfig = binding.value
      let config = DEEP_CLONE(EL_COL_VCONFIG)
      let order = []
      // config从小到大尺寸排列
      for (let v of config) {
        if (valueConfig[v.symbol]) {
          v.col = valueConfig[v.symbol]
          order.push(valueConfig[v.symbol])
        }
      }
      let className = ''
      // 再次遍历赋值其他区间值
      for (let v of config) {
        if (!valueConfig[v.symbol]) {
          v.col = order[0]
        } else {
          order.slice(0, 1)
        }
        // 设置自适应所需要的class
        // className += v.class + EL_COL_GRID(v.col) + ' '
        className += v.class + v.col + ' '
      }
      addClass([el], className)
    }
  }
})

//  添加class
function addClass (dom, kls) {
  var klsReg = new RegExp(kls, 'ig')
  for (var i = 0; i < dom.length; i++) {
    var node = dom[i]
    var klses = node.className
    if (!klsReg.test(klses)) {
      node.className = klses + ' ' + kls
    }
    // console.log(klses)
  }
}
