/**医疗机构首页 */
// 事件中平台对应的事件处理以及状态
export const PLATFORM_STATUS = [
  {
    value: '1',
    label: '完成机构上传数据的处理，开始数据匹配',
    link: ''
  },
  {
    value: '2',
    label: '完成机构上传的数据匹配，发布标准库版本',
    link: '下载标准药品库链接'
  },
  {
    value: '3',
    label: '医疗机构申请治理报告已发布',
    link: '查看治理报告链接'
  },
  {
    value: '4',
    label: '知识库更新，发布标准库版本',
    link: '下载标准药品库链接'
  }
]
// 事件列表
export const EVENT_DATA_LIST = [
  // status: 如果是非平台的事件，则为空字符串
  /* UPLOAD(1, "数据上传"),
  MATCH_DEAL(2, "开始处理"),
  MATCH_FINISH(3, "完成处理"),
  CATALOG_DOWNLOAD(4, "药品库下载"),
  EVAL_APPLY(5, "申请报表"),
  EVAL_PUBLISH(6, "报告发布"),
  DKB_UPDATE(7, "知识库更新"),
  LOGIN(8, "用户登录"),
  EXIT(9, "退出登录") */
  {
    operatorName: '张三',
    logMsg: '上传药品元数据',
    operateTime: '2021-01-09 12:20:09',
    logType: 1
  },
  {
    operatorName: '平台',
    logMsg: '完成xxxx-07-01上传数据处理，开始数据匹配',
    operateTime: '2021-01-09 12:20:09',
    logType: 2
  },
  {
    operatorName: '平台',
    logMsg: ' 完成xxxx-07-01上传数据匹配，发布标准库版本xxxxx.',
    operateTime: '2021-01-09 12:20:09',
    logType: 3 // 下载标准库
  },
  {
    operatorName: '张三',
    logMsg: '用户成功下载了某个版本的标准药品库',
    operateTime: '2021-01-09 12:20:09',
    logType: 4
  },
  {
    operatorName: '张三',
    logMsg: '申请治理报告',
    operateTime: '2021-01-09 12:20:09',
    logType: 5
  },
  {
    operatorName: '平台',
    logMsg: 'xxxx-07-01申请治理报告已发布',
    operateTime: '2021-01-09 12:20:09',
    logType: 6 // 查看报告
  },
  {
    operatorName: '平台',
    logMsg: '知识库更新，发布标准库版本xxxxx',
    operateTime: '2021-01-09 12:20:09',
    logType: 7 // 下载标准药品库
  }
]
// 医疗机构对应的状态以及任务
export const STATUS_TASK = [
  {
    // 不存在待处理/待匹配任务
    value: '0',
    label: '展示最近一次匹配完成任务情况',
    link: '',
    tips: '',
    updateTime: ''
  },
  {
    // 显示待处理和待匹配的任务情况
    value: '1',
    label: '存在待处理/待匹配任务',
    link: '',
    tips: '',
    updateTime: ''
  },
  {
    value: '2',
    label: '存在1个或以上匹配完成任务，但没有一份治理报告',
    link: '还没有治理报告，立即申请！',
    tips: '',
    updateTime: ''
  },
  {
    value: '3',
    label: '存在1个或以上匹配完成任务，且有只有生成中的治理报告',
    link: '',
    tips: '治理报告制作中，敬请等待。',
    updateTime: ''
  },
  {
    // 展示健康指数，如果还有平台正在处理的任务，除了显示健康指数还会显示匹配率（两者可以来回切换）
    value: '4',
    label: '存在1个或以上匹配完成任务，且有1个或多个已发布的治理报告',
    link: '',
    tips: '',
    updateTime: ''
    // 该数据更新于：报告申请时间
  }
]
export const NOTICE_LIST = [
  {
    id: 1,
    title: '药品元数据药品元数据10000条',
    pubTime: '2021-01-09 12:20:09'
  },
  {
    id: 2,
    title: '药品元数据药品元数据10000条',
    pubTime: '2021-01-09 12:20:09'
  },
  {
    id: 3,
    title: '药品元数据药品元数据10000条',
    pubTime: '2021-01-09 12:20:09'
  },
  {
    id: 4,
    title: '药品元数据药品元数据10000条',
    pubTime: '2021-01-09 12:20:09'
  }
]
export const LINK_LIST = [
  {
    value: 1,
    text: '查看当前任务详情'
  },
  {
    value: 2,
    text: '还没有治理报告，立即申请！'
  },
  {
    value: 3,
    text: '治理报告制作中，敬请等待。'
  },
  {
    value: 4,
    text: '当前有正在处理的任务，点击查看详情'
  },
  {
    value: 5,
    text: '当前任务待处理'
  },
  {
    value: 6,
    text: '当前任务待匹配'
  },
  {
    value: '',
    text: ''
  }
]
// 模拟数据
export const CHART_STATUS_OBJ = {
  evalStatusCountMap: {
    1: 0, // 报告生成中数量
    2: 1 // 已发布的数量
  },
  matchStatusCountMap: {
    // 1:已上传 2:待处理 3:待匹配 4:匹配完成
    1: 0,
    2: 1,
    3: 0,
    4: 0
  },
  status: 4,
  recordId: '',
  link: ''
}
// 我的药品库-机构健康指数及雷达图内容
export const CHART_OBJ = {
  matchRatio: '', // 匹配度
  healthIndexScore: '', // 健康指数
  indexCompareAbsoluteValue: '', // 健康指数比较绝对值 如无上次数据,相关内容用不传,需要前端使用 --进行填充
  indexCompareTrend: -1, // 健康指数比较趋势  -1:下降 0:持平 1:上升 空值:无上次数据
  missingDataItemScore: 0, // 数据缺失项
  optimizationItemScore: 0, // 优化项
  dataMatchItemScore: 0, // 未匹配项-数据匹配
  riskItemScore: 0, // 风险项
  updateTime: '', // 数据更新时间
}

// 任务统计
export const TASK_OBJ = {
  avgDealTime: '', // 平均处理时间
  finishCount: '', // 处理完成数量
  pendCount: '', // 待处理数量
  processCount: '' // 处理中数量
}
