<!--klib的菜单-->
<template>
  <el-menu
    :default-active="activeRouteIndex"
    background-color="#fff"
    text-color="#666"
    active-text-color="#000"
    class="menu-header"
    :router="true"
    mode="horizontal">
    <div v-for="(item, index) in routeMenu" :key="index" style="display: inline-block">
      <el-submenu v-if="showsubmenu(item)" :index="item.path">
        <template slot="title">{{ item.meta.title }}</template>
        <el-menu-item v-for="path in item.children" :key="path.path" :index="resolvePath(path.path, item.path)">
          {{ path.meta.title }}
        </el-menu-item>
      </el-submenu>
      <el-menu-item
        v-else-if="showMenu(item)"
        :index="menuPath(item)">
        {{ item | menuTitle }}
      </el-menu-item>
    </div>
  </el-menu>
</template>
<script>
import { mapGetters } from "vuex"
import { isExternal } from '@/utils/validate'
import path from 'path'
export default {
  filters: {
    menuTitle(item) {
      if (item.children && !item.meta) {
        return item.children[0].meta.title
      } else if (item.meta) {
        return item.meta.title
      }
    }
  },
  computed: {
    ...mapGetters([
      'sidebar',
      'sidebarRouters'
    ]),
    activeMenu() {
      const route = this.$route
      const { meta, path } = route
      // if set path, the sidebar will highlight the path you set
      if (meta.activeMenu) {
        return meta.activeMenu
      }
      return path
    },
    /* menuPath: function () {
      return function (item) {
        if (item.path === '/') {
          return '/index'
        } else {
          if (item.meta.parentPath) {
            console.log(item.meta.parentPath)
            return item.meta.parentPath
          } else {
            return item.path
          }
        }
      }
    }, */
    menuPath: {
      get() {
        return function (item) {
          if (item.path === '/') {
            return '/index'
          } else {
            if (item.meta.parentPath) {
              // console.log(item.meta.parentPath)
              return item.meta.parentPath
            } else {
              return item.path
            }
          }
        }
      },
      set(newVal, item) {
        if (newVal) {
          this.activePath = newVal
        }
      }
    },
    showMenu: function () {
      return function (item) {
        if (
          (item.path === '/') ||
          (item.hidden==false && item.component.name== "") ||
          (item.hidden==false && item.component.name== "Layout" && item.children)
        ) {
          return true
        } else {
          return false
        }
      }
    },
    showsubmenu: function() {
      return function (item) {
        if (!item.hidden && item.meta && item.children && item.children.length > 0 && item.alwaysShow) {
          return true
        } else {
          return false
        }
      }
    },
    routeMenu() {
      let arr = []
      for(let item of this.sidebarRouters) {
        if (!item.hidden && item.path === '/') {
          arr.push(item)
        }
      }
      return arr
    },
    activeRouteIndex: {
      get() {
        if (this.currentRouteChanged) {
          return this.activePathIndex
        } else {
          if (this.$route.meta.parentPath) {
            return this.$route.meta.parentPath
          } else {
            return this.$route.path
          }
        }
      },
      set(val) {
        this.activePathIndex = val
      }
    }
  },
  data() {
    return {
      activeIndex: '/',
      activePathIndex: '',
      activePath: '',
      currentRouteChanged: false, // 当前路由是否变化了
      // routeMenu: []
    }
  },
  watch: {
    $route(to, from) {
      // console.log(this.$route)
      this.activeIndex = to.path
      this.$emit('routerEnter', to)
      // console.log(this.activeIndex)
      this.currentRouteChanged = true
      if (to.meta.parentPath) {
        this.activeRouteIndex = to.meta.parentPath
      } else {
        this.activeRouteIndex = to.path
      }
    }
  },
  created() {
    // console.log(this.routeMenu)
    for (let item of this.sidebarRouters) {
      //console.log(item)
    }
  },
  methods: {
    resolvePath(routePath, parentPath) {
      if (isExternal(routePath)) {
        return routePath
      }
      if (isExternal(parentPath)) {
        return parentPath
      }
      // console.log(parentPath, routePath)
      let combinePath = path.resolve(parentPath, routePath)
      // console.log(combinePath)
      // this.activeIndex = combinePath
      return combinePath
    }
  }
}
</script>
<style lang="scss" scoped>
.el-menu-item.is-active {
  border: none !important;
}
.menu-header {
  height: 100% !important;
  /* min-width: 570px; */
  width: auto;
  &.el-menu--horizontal {
    border-bottom: none !important;
  }
  background-color: #fff !important;
  .el-menu-item {
    height: 100% !important;
    line-height: 54px !important;
    font-size: 16px;
  }
  ::v-deep.el-menu-item.is-active, ::v-deep.el-submenu.is-active {
    background-color: #fff !important;
    color: #000 !important;
    border-right: none !important;
    position: relative;
    /* border-bottom: 2px solid $topic-color !important; */
    &::after {
      content: '';
      display: inline-block;
      width: 60px;
      height: 2px;
      background-color: $topic-color;
      position: absolute;
      bottom: 0px;
      left: 50%;
      margin-left: -30px;
    }
    /* .el-submenu__title {
      border-bottom: 2px solid $topic-color !important;
    } */
  }
  .el-submenu {
    padding: 0 10px;
    ::v-deep.el-submenu__title {
      background: #fff !important;
      height: 100% !important;
      line-height: 56px !important;
      position: relative;
      /* padding: 0 !important; */
    }
  }
  ::v-deep.el-submenu__icon-arrow {
    right: 0px !important;
  }
}
</style>
