import Vue from 'vue'
import Vuex from 'vuex'
import app from '@/store/modules/app'
import user from './modules/user'
import tagsView from '@/store/modules/tagsView'
import permission from './modules/permission'
import settings from './modules/settings'
import concept from '@/store/modules/concept'
import sysCache from '@/store/modules/sysCache'
import getters from '@/store/getters'
import dict from './modules/dict'
Vue.use(Vuex)

const store = new Vuex.Store({
  modules: {
    app,
    user,
    tagsView,
    permission,
    settings,
    concept,
    sysCache,
    dict
  },
  getters
})

export default store
