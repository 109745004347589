
import {
  getPostTreeApi
} from '@/modules/crm/api/crm/org'
const state = {
  title:'测试',
  dicts:{},
  customDeptPostTree: null, // 机构管理--客户--部门、职位
  dealerDeptPostTree: null, // 机构管理--经销商--部门、职位
  distributorDeptPostTree: null // 机构管理--分销商--部门、职位
}


const mutations = {
  CHANGE_SETTING: (state, { key, value }) => {
    if (state.hasOwnProperty(key)) {
      state[key] = value
    }
  },
  ADD_DICT: (state, { key, value }) => {
    state.dicts[key]=value
  },
  ADD_POST_TREE(state, res) {
    state.customDeptPostTree = res[0].data || []
    state.dealerDeptPostTree = res[1].data || []
    state.distributorDeptPostTree = res[2].data || []
  },
  GET_POST_TREE(state) {
    state.customDeptPostTree = state.customDeptPostTree
    state.dealerDeptPostTree = state.dealerDeptPostTree
    state.distributorDeptPostTree = state.distributorDeptPostTree
  }
}

const actions = {
  changeSetting({ commit }, data) {
    commit('CHANGE_SETTING', data)
  },
  addDict({ commit },data) {
    commit('ADD_DICT',data)
  },
  async fetchDeptAndPostTree({ commit,rootState }) {
    console.log(rootState)
    if (rootState.dict.customDeptPostTree) {
      commit('GET_POST_TREE')
      return
    }
    const res2 = getPostTreeApi(2)
    const res3 = getPostTreeApi(3)
    const res4 = getPostTreeApi(4)
    await Promise.all([res2, res3, res4]).then(res => {
      // this.postOptions[0].children = res[0].data || []
      // this.postOptions[1].children = res[1].data || []
      console.log(res)
      commit('ADD_POST_TREE', res)
    })
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
