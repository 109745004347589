/**医疗机构首页所用到的接口 */
import request from '@/utils/request'

// 是否存在待处理或待匹配状态任务 true为存在
export function getTaskApi() {
  return request({
    url: '/dm/org/home/sniff/process',
    method: 'get'
  })
}
// 是否存在已发布治理报告 如果存在返回true
export function getPublishReportApi() {
  return request({
    url: '/dm/org/home/sniff/publish',
    method: 'get'
  })
}
// 我的药品库-机构健康指数及雷达图内容
export function getChartApi() {
  return request({
    url: '/dm/org/home/health/info',
    method: 'get'
  })
}
// 事件记录
export function getEventApi() {
  return request({
    url: '/dm/org/home/log',
    method: 'get'
  })
}
// 事件记录中链接（下载、查看）
export function getEventLinkApi(data) {
  if (data.uri.indexOf('publish/download') > -1) {
    // 下载
    return request({
      url: '/dm' + data.uri,
      method: 'get',
      resopnseReturn: true, // axios数据返回的是一个整体
      responseType: 'blob'
    })
  } else {
    // 查看
    if (data.uri !== '/org/eval/result/list') {
      // 这个请求为post请求
      return request({
        url: '/dm' + data.uri,
        method: 'get'
      })
    } else {
      return request({
        url: '/dm' + data.uri,
        method: 'post'
      })
    }
  }
}
// 任务统计
export function getTaskCountApi() {
  return request({
    url: '/dm/org/home/task/statistics',
    method: 'get'
  })
}
// 首页图表模块部分，根据机构的任务和治理报告的状态来显示不同的内容
export function getStatusApi() {
  return request({
    url: '/dm/org/home/sniff/status',
    method: 'get'
  })
}
// 公告
export function getNoticeApi() {
  return request({
    url: '/dm/org/home/notice',
    method: 'get'
  })
}
// 公告设置为已读
export function updateNoticeApi(data) {
  return request({
    url: '/dm/org/home/notice/read/' + data,
    method: 'put'
  })
}
// 治理报告申请 /dm/org/eval/apply
export function applyReportApi() {
  return request({
    url: '/dm/org/eval/apply',
    method: 'POST'
  })
}
