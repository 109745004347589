import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

/* Layout */
import Layout from '@/layout'
import ParentView from '@/components/ParentView';
// import ServiceAgreement from '@/assets/html/serviceAgreement.html'
/**
 * Note: 路由配置项
 *
 * hidden: true                   // 当设置 true 的时候该路由不会再侧边栏出现 如401，login等页面，或者如一些编辑页面/edit/1
 * alwaysShow: true               // 当你一个路由下面的 children 声明的路由大于1个时，自动会变成嵌套的模式--如组件页面
 *                                // 只有一个时，会将那个子路由当做根路由显示在侧边栏--如引导页面
 *                                // 若你想不管路由下面的 children 声明的个数都显示你的根路由
 *                                // 你可以设置 alwaysShow: true，这样它就会忽略之前定义的规则，一直显示根路由
 * redirect: noRedirect           // 当设置 noRedirect 的时候该路由在面包屑导航中不可被点击
 * name:'router-name'             // 设定路由的名字，一定要填写不然使用<keep-alive>时会出现各种问题
 * meta : {
    noCache: true                // 如果设置为true，则不会被 <keep-alive> 缓存(默认 false)
    title: 'title'               // 设置该路由在侧边栏和面包屑中展示的名字
    icon: 'svg-name'             // 设置该路由的图标，对应路径src/assets/icons/svg
    breadcrumb: false            // 如果设置为false，则不会在breadcrumb面包屑中显示
  }
 */

// 公共路由
export const constantRoutes = [
  {
    path: '/redirect',
    component: Layout,
    hidden: true,
    children: [
      {
        path: '/redirect/:path(.*)',
        component: (resolve) => require(['@/views/redirect'], resolve)
      }
    ]
  },
  {
    path: '/login',
    component: (resolve) => require(['@/views/login/index'], resolve),
    hidden: true
  },
  {
    path: '/jsonDiff',
    component: (resolve) => require(['@/views/demo/jsonDiff/jsonDiff'], resolve),
    hidden: true
  },
  {
    path: '/findPwd',
    component: (resolve) => require(['@/views/login/components/FindPwd'], resolve),
    hidden: true
  },
  {
    path: '/register',
    component: (resolve) => require(['@/views/login/components/Register'], resolve),
    hidden: true
  },
  {
    path: '/404',
    component: (resolve) => require(['@/views/error/404'], resolve),
    hidden: true
  },
  {
    path: '/401',
    component: (resolve) => require(['@/views/error/401'], resolve),
    hidden: true
  },
  {
    path: '/demo',
    component: (resolve) => require(['@/views/demo/drug'], resolve),
    hidden: true
  },
  {
    path: '/serviceAgreement',
    // name: 'ServiceAgreement',
    component: (resolve) => require(['@/views/other/serviceAgreement'], resolve),
    // component: { template: ServiceAgreement },
    // component: ServiceAgreement,
    hidden: true
  },
  {
    path: '/privacyPolicy',
    name: 'PrivacyPolicy',
    component: (resolve) => require(['@/views/other/privacyPolicy'], resolve),
    hidden: true
  },
  {
    path: '/ruleOfChildren',
    name: 'RuleOfChildren',
    component: (resolve) => require(['@/views/other/ruleOfChildren'], resolve),
    hidden: true
  },
  {
    path: '/serviceIntro',
    name: 'ServiceIntro',
    component: (resolve) => require(['@/views/login/components/ServiceIntro'], resolve),
    hidden: true
  },
  {
    path: '/',
    component: Layout,
    redirect: 'index',
    children: [
      {
        path: 'index',
        component: (resolve) => require(['@/views/index'], resolve),
        name: '首页',
        meta: { title: '首页', icon: 'dashboard', noCache: true, affix: true }
      },
      {
        path: '/drugMetadataUpload/list/matchView/:id(\\d+)',
        component: (resolve) => require(['@/views/dm/drugMetadataUpload/matchView'], resolve),
        name: 'drugMetadataUploadMatchView',
        hidden: true,
        meta: { title: '药品元数据匹配查看', hidden: true, parentPath: '/drugMetadataUpload/list' }
      },
      {
        path: '/institutionDrugStorage/targetDataReview',
        component: (resolve) => require(['@/views/dm/targetDataReview'], resolve),
        name: 'TargetDataReview',
        hidden: true,
        meta: { title: '目标数据审核', parentPath: '/institutionDrugStorage/detail' }
      },
      {
        path: '/dm/viewReport',
        component: (resolve) => require(['@/views/dm/platformGovernanceReport/components/ViewReport'], resolve),
        name: 'ViewReport',
        hidden: true,
        meta: { title: '查看治理报告', hidden: true}
      },
      {
        path: '/dm/checkReport',
        component: (resolve) => require(['@/views/dm/platformGovernanceReport/components/CheckReportTable'], resolve),
        name: 'CheckReport',
        hidden: true,
        meta: { title: '审核治理报告', hidden: true }
      },
      // 知识库版本升级编辑
      {
        path: '/dm/editDrugMatch',
        component: (resolve) => require(['@/views/dm/knowledgeBaseVersionUpgrade/components/EditMatch'], resolve),
        name: 'EditDrugMatch',
        hidden: true,
        meta: { title: '修改药品匹配' }
      },
      // 知识库版本升级查看
      {
        path: '/dm/viewDrugMatch',
        component: (resolve) => require(['@/views/dm/knowledgeBaseVersionUpgrade/components/ViewMatch'], resolve),
        name: 'ViewDrugMatch',
        hidden: true,
        meta: { title: '查看药品匹配' }
      },
      // 映射关系维护
      {
        path: '/dm/viewMapping',
        component: (resolve) => require(['@/views/dm/mappingMaintenance/components/ViewMatch'], resolve),
        name: 'ViewMapping',
        hidden: true,
        meta: { title: '查看映射关系' }
      },
      {
        path: '/dm/editMapping',
        component: (resolve) => require(['@/views/dm/mappingMaintenance/components/EditMatch'], resolve),
        name: 'EditMapping',
        hidden: true,
        meta: { title: '维护映射关系' }
      },
      {
        path: '/dm/newDrugWarehousingHandle',
        component: (resolve) => require(['@/views/dm/newDrugWarehousing/NewDrugWarehousingHandle'], resolve),
        name: 'NewDrugWarehousingHandle',
        hidden: true,
        meta: {
          title: '新药入库处理',
        }
      },
      {
        path: '/dm/newDrugWarehousingView',
        component: (resolve) => require(['@/views/dm/newDrugWarehousing/NewDrugWarehousingHandle'], resolve),
        name: 'NewDrugWarehousingHandle',
        hidden: true,
        meta: {
          title: '新药入库查看',
          // parentPath: '/dm/newDrugWarehousing'
        }
      },
      {
        path: '/dm/klib',
        component: (resolve) => require(['@/views/dm/klib/index'], resolve),
        name: 'klib',
        hidden: true,
        meta: {
          title: 'klib下载',
          // parentPath: '/dm/newDrugWarehousing'
        }
      }
    ]
  },
  {
    path: '/user',
    component: Layout,
    hidden: true,
    redirect: 'noredirect',
    children: [
      {
        path: 'profile',
        component: (resolve) => require(['@/views/system/user/profile/index'], resolve),
        name: 'Profile',
        meta: { title: '个人中心', icon: 'user' }
      }
    ]
  },
  {
    path: '/dict',
    component: Layout,
    hidden: true,
    children: [
      {
        path: 'type/data/:dictId(\\d+)',
        component: (resolve) => require(['@/views/system/dict/data'], resolve),
        name: 'Data',
        meta: { title: '字典数据', icon: '' }
      }
    ]
  },
  {
    path: '/job',
    component: Layout,
    hidden: true,
    children: [
      {
        path: 'log',
        component: (resolve) => require(['@/views/monitor/job/log'], resolve),
        name: 'JobLog',
        meta: { title: '调度日志' }
      }
    ]
  },
  {
    path: '/gen',
    component: Layout,
    hidden: true,
    children: [
      {
        path: 'edit/:tableId(\\d+)',
        component: (resolve) => require(['@/views/tool/gen/editTable'], resolve),
        name: 'GenEdit',
        meta: { title: '修改生成配置' }
      }
    ]
  },
  {
    path: '/substance',
    component: Layout,
    hidden: true,
    children: [
      {
        path: 'edit/:id(\\d+)',
        component: (resolve) => require(['@/views/dkm/substance/edit'], resolve),
        name: 'SubstanceEdit',
        hidden: true,
        meta: { title: '修改物质' }
      },
      {
        path: 'edit',
        component: (resolve) => require(['@/views/dkm/substance/edit'], resolve),
        name: 'SubstanceEdit',
        hidden: true,
        meta: { title: '新增物质' }
      },
      {
        path: 'search/:id(\\d+)',
        component: (resolve) => require(['@/views/dkm/substance/search'], resolve),
        name: 'SubstanceSearch',
        hidden: true,
        meta: { title: '物质查看' }
      }
    ]
  },
  {
    path: '/drug',
    name: 'drug',
    component: Layout,
    children: [
      {
        path: 'edit/:id(\\d+)',
        component: (resolve) => require(['@/views/dkm/drug/edit'], resolve),
        name: 'drugEdit',
        hidden: true,
        meta: { title: '修改药物' }
      },
      {
        path: 'editOligomictDrug/:id(\\d+)',
        component: (resolve) => require(['@/views/dkm/drug/edit'], resolve),
        name: 'drugEdit',
        hidden: true,
        meta: { title: '修改药物' }
      },
      {
        path: 'edit',
        component: (resolve) => require(['@/views/dkm/drug/edit'], resolve),
        name: 'drugEdit',
        hidden: true,
        meta: { title: '新增药物' }
      },
      {
        path: 'search/:id(\\d+)',
        component: (resolve) => require(['@/views/dkm/drug/search'], resolve),
        name: 'drugSearch',
        hidden: true,
        meta: { title: '药物查看' }
      }
    ]
  },
  {
    path: '/drugGroup',
    name: 'drugGroup',
    component: Layout,
    children: [
      {
        path: 'edit/:id(\\d+)',
        component: (resolve) => require(['@/views/dkm/drugGroup/edit'], resolve),
        name: 'drugGroupEdit',
        hidden: true,
        meta: { title: '修改药物组' }
      },
      {
        path: 'edit',
        component: (resolve) => require(['@/views/dkm/drugGroup/edit'], resolve),
        name: 'drugGroupEdit',
        hidden: true,
        meta: { title: '新增药物组' }
      },
      {
        path: 'search/:id(\\d+)',
        component: (resolve) => require(['@/views/dkm/drugGroup/search'], resolve),
        name: 'drugGroupSearch',
        hidden: true,
        meta: { title: '药物组查看' }
      }
    ]
  },
  {
    path: '/preparationSpecification',
    name: 'preparationSpecification',
    component: Layout,
    children: [
      {
        path: 'view/:id(\\d+)',
        component: (resolve) => require(['@/views/dkm/preparationSpecification/View'], resolve),
        name: 'PreparationSpecificationView',
        hidden: true,
        meta: { title: '查看制剂' }
      },
      {
        path: 'edit/:id(\\d+)',
        component: (resolve) => require(['@/views/dkm/preparationSpecification/edit'], resolve),
        name: 'preparationSpecificationEdit',
        hidden: true,
        meta: { title: '修改制剂' }
      },
      {
        path: 'edit',
        component: (resolve) => require(['@/views/dkm/preparationSpecification/edit'], resolve),
        name: 'preparationSpecificationEdit',
        hidden: true,
        meta: { title: '新增制剂' }
      }
    ]
  },
  {
    path: '/drugProd',
    component: Layout,
    hidden: true,
    children: [
      {
        path: 'drugUseInstruction',
        component: (resolve) => require(['@/views/dkm/drugProd/drugUseInstruction'], resolve),
        name: 'drugUseInstruction',
        hidden: true,
        meta: { title: '用药指导' }
      },
      {
        path: 'view/:id(\\d+)',
        component: (resolve) => require(['@/views/dkm/drugProd/View'], resolve),
        name: 'DrugProdView',
        hidden: true,
        meta: { title: '查看药品' }
      },
      {
        path: 'list',
        component: (resolve) => require(['@/views/dkm/drugProd/index'], resolve),
        name: 'drugProd',
        hidden: true,
        meta: { title: '药品与包装' }
      },
      {
        path: 'edit/:id(\\d+)',
        component: (resolve) => require(['@/views/dkm/drugProd/edit'], resolve),
        name: 'DrugProdEdit',
        hidden: true,
        meta: { title: '药品修改' }
      },
      {
        path: 'edit',
        component: (resolve) => require(['@/views/dkm/drugProd/edit'], resolve),
        name: 'DrugProdEdit',
        hidden: true,
        meta: { title: '药品新增' }
      },
      {
        path: 'copyEdit/:id(\\d+)',
        component: (resolve) => require(['@/views/dkm/drugProd/edit'], resolve),
        name: 'DrugProdEdit',
        hidden: true,
        meta: { title: '药品复制新增' }
      },
      {
        path: 'addByPpSpecId/:specId(\\d+)',
        component: (resolve) => require(['@/views/dkm/drugProd/edit'], resolve),
        name: 'DrugProdEdit',
        hidden: true,
        meta: { title: '药品新增' }
      }
    ]
  },
  {
    path: '/pkgDrugProd',
    component: Layout,
    hidden: true,
    children: [
      {
        path: 'edit/:id(\\d+)',
        component: (resolve) => require(['@/views/dkm/pkgDrugProd/edit'], resolve),
        name: 'PkgDrugProdEdit',
        hidden: true,
        meta: { title: '组合包装药品修改' }
      },
      {
        path: 'edit',
        component: (resolve) => require(['@/views/dkm/pkgDrugProd/edit'], resolve),
        name: 'PkgDrugProdEdit',
        hidden: true,
        meta: { title: '组合包装药品新增' }
      }
    ]
  },
  {
    path: '/drugCategoryTreeList',
    name: 'drugCategoryTreeList',
    component: Layout,
    children: [
      {
        path: 'list',
        component: (resolve) => require(['@/views/dkm/drugCategoryTreeList/index'], resolve),
        name: 'drugCategoryTreeList',
        hidden: true,
        meta: { title: '药品类别目录树' }
      }
    ]
  },
  {
    path: '/servicePackageManagement',
    name: 'servicePackageManagement',
    component: Layout,
    children: [
      {
        path: 'list',
        component: (resolve) => require(['@/views/system/servicePackageManagement/index'], resolve),
        name: 'servicePackageManagement',
        hidden: true,
        meta: { title: '服务包管理' }
      }
    ]
  },
  {
    path: '/organizationRegistrationApproval',
    name: 'organizationRegistrationApproval',
    component: Layout,
    children: [
      {
        path: 'list',
        component: (resolve) => require(['@/views/dm/organizationRegistrationApproval/index'], resolve),
        name: 'organizationRegistrationApproval',
        hidden: true,
        meta: { title: '机构注册审批' }
      }
    ]
  },
  {
    path: '/drugMetadataMatching',
    name: 'drugMetadataMatching',
    component: Layout,
    children: [
      {
        path: 'list',
        component: (resolve) => require(['@/views/dm/drugMetadataMatching/index'], resolve),
        name: 'drugMetadataMatching',
        hidden: true,
        meta: { title: '药品元数据管理' }
      },
      {
        path: 'mapping/:orgId(\\d+)',
        component: (resolve) => require(['@/views/dm/drugMetadataMatching/mapping'], resolve),
        name: 'drugMetadataMatchingMapping',
        hidden: true,
        // meta: { title: '药品元数据药品映射' }
        meta: { title: '药品匹配' }
      },
      {
        path: 'mappingLook/:orgId(\\d+)',
        component: (resolve) => require(['@/views/dm/drugMetadataMatching/mapping'], resolve),
        name: 'drugMetadataMatchingMapping', // drugMetadataMatchingMapping
        hidden: true,
        meta: { title: '药品查看' }
      },
      {
        path: 'codeMapping/:orgId(\\d+)',
        component: (resolve) => require(['@/views/dm/drugMetadataMatching/codeMapping'], resolve),
        name: 'drugMetadataMatchingCodeMapping',
        hidden: true,
        // meta: { title: '药品元数据代码映射' }
        meta: { title: '代码匹配' }
      },
      {
        path: 'codeMappingLook/:orgId(\\d+)',
        component: (resolve) => require(['@/views/dm/drugMetadataMatching/codeMapping'], resolve),
        name: 'drugMetadataMatchingCodeMapping',
        hidden: true,
        meta: { title: '代码查看' }
      },
      {
        path: 'pretreatment/:recordId(\\d+)',
        component: (resolve) => require(['@/views/dm/drugMetadataMatching/pretreatment'], resolve),
        name: 'drugMetadataMatchingPretreatment',
        hidden: true,
        meta: { title: '上传文档预处理' }
      }
    ]
  },

  // {
  //   path: '/institutionGovernanceReport',
  //   name: 'institutionGovernanceReport',
  //   component: Layout,
  //   children: [
  //     {
  //       path: 'detail',
  //       component: (resolve) => require(['@/views/dm/institutionGovernanceReport/index'], resolve),
  //       name: 'institutionGovernanceReport',
  //       hidden: true,
  //       meta: { title: '机构治理报告' }
  //     }
  //   ]
  // },
  // {
  //   path: '/drugMetadataUpload',
  //   name: 'DrugMetadataUpload',
  //   component: Layout,
  //   hidden: true,
  //   children: [
  //     {
  //       path: 'list',
  //       component: (resolve) => require(['@/views/dm/drugMetadataUpload/index'], resolve),
  //       name: 'DrugMetadataUpload',
  //       hidden: true,
  //       meta: { title: '药品元数据上传' }
  //     },
  //     {
  //       path: 'matchView/:id(\\d+)',
  //       component: (resolve) => require(['@/views/dm/drugMetadataUpload/matchView'], resolve),
  //       name: 'drugMetadataUploadMatchView',
  //       hidden: true,
  //       meta: { title: '药品元数据匹配查看', hidden: true }
  //     },
  //   ]
  // },
  /* {
    path: '/institutionGovernanceReport',
    name: 'institutionGovernanceReport',
    component: Layout,
    hidden: true,
    children: [
      {
        path: 'detail',
        component: (resolve) => require(['@/views/dm/institutionGovernanceReport'], resolve),
        name: 'institutionGovernanceReport',
        hidden: true,
        meta: { title: '机构治理报告' }
      }
    ]
  },
  {
    path: '/institutionDrugStorage',
    name: 'institutionDrugStorage',
    component: Layout,
    hidden: true,
    children: [
      {
        path: 'detail',
        component: (resolve) => require(['@/views/dm/institutionDrugStorage'], resolve),
        name: 'institutionDrugStorage',
        hidden: true,
        meta: { title: '机构药品库' }
      },
      {
        path: 'targetDataReview',
        component: (resolve) => require(['@/views/dm/targetDataReview'], resolve),
        name: 'TargetDataReview',
        hidden: true,
        meta: { title: '目标数据审核' }
      }
    ]
  }, */
  /* {
    path: '/dm',
    component: Layout,
    hidden: true,
    children: [
      {
        path: 'viewReport',
        component: (resolve) => require(['@/views/dm/platformGovernanceReport/components/ViewReport'], resolve),
        name: 'ViewReport',
        meta: { title: '查看治理报告', hidden: true}
      },
      {
        path: 'checkReport',
        component: (resolve) => require(['@/views/dm/platformGovernanceReport/components/CheckReportTable'], resolve),
        name: 'CheckReport',
        meta: { title: '审核治理报告', hidden: true }
      },
      // 知识库版本升级编辑
      {
        path: 'editDrugMatch',
        component: (resolve) => require(['@/views/dm/knowledgeBaseVersionUpgrade/components/EditMatch'], resolve),
        name: 'EditDrugMatch',
        hidden: true,
        meta: { title: '修改药品匹配' }
      },
      // 知识库版本升级查看
      {
        path: 'viewDrugMatch',
        component: (resolve) => require(['@/views/dm/knowledgeBaseVersionUpgrade/components/ViewMatch'], resolve),
        name: 'ViewDrugMatch',
        hidden: true,
        meta: { title: '查看药品匹配' }
      },
      // 映射关系维护
      {
        path: 'viewMapping',
        component: (resolve) => require(['@/views/dm/mappingMaintenance/components/ViewMatch'], resolve),
        name: 'ViewMapping',
        hidden: true,
        meta: { title: '查看映射关系' }
      },
      {
        path: 'editMapping',
        component: (resolve) => require(['@/views/dm/mappingMaintenance/components/EditMatch'], resolve),
        name: 'EditMapping',
        hidden: true,
        meta: { title: '维护映射关系' }
      }
    ]
  }, */

  // 编辑粉丝信息
  {
    path: '/fansManagement',
    name: 'fansManagement',
    component: Layout,
    children: [
      {
        path: 'fansInformationEditing/:id(\\d+)',
        component: (resolve) => require(['@/views/frp/fansManagement/fansInformationEditing'], resolve),
        name: 'fansInformationEditing',
        hidden: true,
        meta: { title: '编辑粉丝信息' }
      }
    ]
  },
  {
    path: '/userHome',
    name: 'userHome',
    component: Layout,
    children: [
      {
        path: 'personInfoList',
        component: (resolve) => require(['@/modules/thys/view/userHome/component/personInfoList'], resolve),
        name: 'personInfoList',
        hidden: true,
        meta: { title: '作者管理' }
      },
      {
        path: 'handleManuscriptCompenent',
        component: (resolve) => require(['@/modules/thys/view/userHome/component/handleManuscriptCompenent'], resolve),
        name: 'handleManuscriptCompenent',
        hidden: true,
        meta: { title: '稿件管理' }
      }
      ,
      {
        path: 'manuscriptIncomeCompenent',
        component: (resolve) => require(['@/modules/thys/view/userHome/component/manuscriptIncomeCompenent'], resolve),
        name: 'manuscriptIncomeCompenent',
        hidden: true,
        meta: { title: '稿酬管理' }
      }
    ]
  },
  {
    path: '/purchaseBatch',
    component: Layout,
    hidden: true,
    children: [
      {
        path: 'recordList',
        component: (resolve) => require(['@/views/dkm/purchaseBatch/recordList'], resolve),
        name: 'RecordList',
        hidden: true,
        meta: { title: '招采批次管理' }
      },
      {
        path: 'recordDrugList/:id(\\d+)',
        component: (resolve) => require(['@/views/dkm/purchaseBatch/recordDrugList'], resolve),
        name: 'RecordDrugList',
        hidden: true,
        meta: { title: '集采药品目录' }
      },
      {
        path: 'replaceDrugList/:id(\\d+)',
        component: (resolve) => require(['@/views/dkm/purchaseBatch/replaceDrugList'], resolve),
        name: 'ReplaceDrugList',
        hidden: true,
        meta: { title: '可替代药品目录' }
      }
    ]
  },
]

export default new Router({
  //mode: 'history', // 去掉url中的#
  scrollBehavior: () => ({ y: 0 }),
  routes: constantRoutes
})
