var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return Number(_vm.deptType) !== 1 && Number(_vm.deptType) !== 8
    ? _c(
        "div",
        {
          staticClass: "app-wrapper",
          class: _vm.classObj,
          style: { "--current-color": _vm.theme },
        },
        [
          _vm.device === "mobile" && _vm.sidebar.opened
            ? _c("div", {
                staticClass: "drawer-bg",
                on: { click: _vm.handleClickOutside },
              })
            : _vm._e(),
          _c("sidebar", {
            staticClass: "sidebar-container",
            style: {
              backgroundColor:
                _vm.sideTheme === "theme-crm"
                  ? _vm.variables.crmMenuBg
                  : _vm.variables.menuLightBg,
            },
          }),
          _c(
            "div",
            {
              staticClass: "main-container",
              class: { hasTagsView: _vm.needTagsView },
            },
            [
              _c(
                "div",
                { class: { "fixed-header": _vm.fixedHeader } },
                [_c("navbar")],
                1
              ),
              _c("app-main"),
              _c(
                "div",
                { staticClass: "layout-bottom-bar" },
                [
                  _c(
                    "div",
                    [
                      _c("img", {
                        staticStyle: {
                          width: "20px",
                          height: "20px",
                          "margin-top": "10px",
                          float: "left",
                        },
                        attrs: {
                          src: require("../assets/images/icon.png"),
                          alt: "",
                        },
                      }),
                      [
                        _vm.sideTheme === "theme-crm"
                          ? _c(
                              "span",
                              { staticStyle: { "padding-left": "5px" } },
                              [_vm._v(_vm._s(_vm.crmOpennessConfig.sysVersion))]
                            )
                          : _c(
                              "span",
                              { staticStyle: { "padding-left": "5px" } },
                              [
                                _vm._v(
                                  _vm._s(
                                    _vm.opennessConfig.webSystemLatestVersion
                                  )
                                ),
                              ]
                            ),
                      ],
                    ],
                    2
                  ),
                  _vm._m(0),
                  [
                    _vm.sideTheme === "theme-crm"
                      ? _c(
                          "span",
                          {
                            staticStyle: {
                              color: "red",
                              "margin-left": "20px",
                              "font-size": "18px",
                            },
                          },
                          [_vm._v(_vm._s(_vm.crmOpennessConfig.sysEnvName))]
                        )
                      : _c(
                          "span",
                          {
                            staticStyle: {
                              color: "red",
                              "margin-left": "20px",
                              "font-size": "18px",
                            },
                          },
                          [_vm._v(_vm._s(_vm.sysEnvMsg))]
                        ),
                  ],
                ],
                2
              ),
              _vm.showSettings
                ? _c("right-panel", [_c("settings")], 1)
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      )
    : Number(_vm.deptType) === 1
    ? _c(
        "el-container",
        { class: _vm.instiContainerClass },
        [
          _c(
            "el-header",
            { class: _vm.instiHeaderClass },
            [_c("insti-header")],
            1
          ),
          _c("el-main", { class: _vm.instiMainClass }, [_c("insti-main")], 1),
        ],
        1
      )
    : Number(_vm.deptType) === 8
    ? _c(
        "el-container",
        { staticClass: "klib-container layout-container" },
        [
          _c(
            "el-header",
            { class: _vm.instiHeaderClass },
            [_c("klib-header")],
            1
          ),
          _c(
            "el-main",
            { staticClass: "klib-main-container", class: _vm.instiMainClass },
            [_c("insti-main")],
            1
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _c(
        "span",
        { staticStyle: { "font-size": "18px", "vertical-align": "middle" } },
        [_vm._v("©")]
      ),
      _vm._v("2022 上海卫心科技有限公司"),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }