var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-menu",
    {
      staticClass: "menu-header",
      attrs: {
        "default-active": _vm.activeRouteIndex,
        "background-color": "#fff",
        "text-color": "#666",
        "active-text-color": "#000",
        router: true,
        mode: "horizontal",
      },
    },
    _vm._l(_vm.routeMenu, function (item, index) {
      return _c(
        "div",
        { key: index, staticStyle: { display: "inline-block" } },
        [
          _vm.showsubmenu(item)
            ? _c(
                "el-submenu",
                { attrs: { index: item.path } },
                [
                  _c("template", { slot: "title" }, [
                    _vm._v(_vm._s(item.meta.title)),
                  ]),
                  _vm._l(item.children, function (path) {
                    return !path.hidden
                      ? _c(
                          "el-menu-item",
                          {
                            key: path.path,
                            attrs: {
                              index: _vm.resolvePath(path.path, item.path),
                            },
                          },
                          [_vm._v(" " + _vm._s(path.meta.title) + " ")]
                        )
                      : _vm._e()
                  }),
                ],
                2
              )
            : _vm.showMenu(item)
            ? _c("el-menu-item", { attrs: { index: _vm.menuPath(item) } }, [
                _vm._v(" " + _vm._s(_vm._f("menuTitle")(item)) + " "),
              ])
            : _vm._e(),
        ],
        1
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }