<template>
  <div class="sidebar-logo-container" :class="{'collapse':collapse}" :style="{ backgroundColor: sideTheme === 'theme-crm' ? variables.crmMenuBg : variables.menuLightBg }">
    <transition name="sidebarLogoFade">
      <router-link v-if="collapse" key="collapse" class="sidebar-logo-link" to="/">
        <img v-if="logo" :src="logo" class="sidebar-logo">
        <h1 v-else class="sidebar-title" :style="{ color: sideTheme === 'theme-crm' ? variables.sidebarTitle : variables.sidebarLightTitle }">{{ title }} </h1>
      </router-link>
      <router-link v-else key="expand" class="sidebar-logo-link" to="/">
<!--        <img v-if="logo" :src="logo" class="sidebar-logo">-->
        <img :src="logo" class="" style="margin-left: -80px;width: 38%;height: auto;" v-if="settings.sideTheme!== 'theme-crm'"/>
        <img :src="logo" class="" style="margin-left: -60px;width: 112px;height: 34px;" v-if="settings.sideTheme === 'theme-crm'" />
        <!-- <h1 class="sidebar-title" :style="{ color: sideTheme === 'theme-dark' ? variables.sidebarTitle : variables.sidebarLightTitle }">{{ title }} </h1>
        <h3 class="sidebar-title-s">Pharmaceutical Data Factory</h3> -->
      </router-link>
    </transition>
  </div>
</template>

<script>
// import logoImg from '@/assets/logo/logo.png'
import logoImg from '@/assets/images/pdBox-menu.png'
import crmLogoImg from '@/assets/images/crm-menu.png'
import logoImgFolder from '@/assets/images/PD-BOX-folder.png'
import variables from '@/assets/styles/variables.scss'
import { mapState } from 'vuex'

export default {
  name: 'SidebarLogo',
  props: {
    collapse: {
      type: Boolean,
      required: true
    }
  },
  computed: {
    ...mapState(["settings"]),
    variables() {
      return variables;
    },
	  sideTheme() {
      return this.$store.state.settings.sideTheme
    }
  },
  watch: {
    collapse(val) {
      // 菜单是否折叠收缩
      console.log(val)
      if (val) {
        this.logo = logoImgFolder
      } else {
        this.logo =this.settings.sideTheme=== 'theme-crm'?crmLogoImg:logoImg
      }
    }
  },
  data() {
    return {
      title: 'PDBox-药数矩阵',
      logo: logoImg
    }
  },
  created() {
    if (this.collapse) {
      this.logo = logoImgFolder
    } else {
      this.logo =this.settings.sideTheme=== 'theme-crm'?crmLogoImg:logoImg
    }
  }
}
</script>

<style lang="scss" scoped>
.sidebarLogoFade-enter-active {
  transition: opacity 1.5s;
}

.sidebarLogoFade-enter,
.sidebarLogoFade-leave-to {
  opacity: 0;
}

.sidebar-logo-container {
  padding-top:8px;
  height: 50px;
  position: relative;
  width: 100%;
  /*height: 50px;
  line-height: 50px;*/
  background: #2b2f3a;
  text-align: center;
  overflow: hidden;

  & .sidebar-logo-link {
    height: 100%;
    width: 100%;

    & .sidebar-logo {
      width: 16px;
      height: auto;
      vertical-align: middle;
      margin-right: 12px;
    }

    & .sidebar-title {
      display: inline-block;
      margin: 0;
      color: #fff;
      font-weight: 600;
     /* line-height: 10px;*/
      font-size: 14px;
      font-family: Avenir, Helvetica Neue, Arial, Helvetica, sans-serif;
      vertical-align: middle;
    }
    & .sidebar-title-s {
      display: inline-block;
      margin: 0;
      color: #fff;
      font-weight: 600;
     /* line-height: 50px;*/
      font-size: 10px;
      font-family: Avenir, Helvetica Neue, Arial, Helvetica, sans-serif;
      vertical-align: middle;
    }
  }

  &.collapse {
    .sidebar-logo {
      margin-right: 0px;
    }
  }
}
</style>
