import Vue from 'vue'

import Cookies from 'js-cookie'

import Element from 'element-ui'
// import Element from 'winning-element-ui'
import { Table } from 'winxin-element-ui';
import './assets/styles/element-variables.scss'

import '@/assets/styles/index.scss' // global css
import '@/assets/styles/common.scss' // common css
import App from './App'
import store from './store'
import router from './router'
import permission from './directive/permission'
import columnSettings from './utils/columnSettings.js'
import edtColumnSettings from './utils/edtColumnSettings.js'
import basePrototype from './utils/basePrototype'
import 'font-awesome/css/font-awesome.css'

import './assets/icons' // icon
import './permission' // permission control
import { getDicts } from "@/api/system/dict/data";
import { getConfigKey } from "@/api/system/config";
import { parseTime, resetForm, clearFormValidate,addDateRange, selectDictLabel, selectDictLabels, download, handleTree } from "@/utils/common";
import Pagination from "@/components/Pagination";
// 自定义表格工具扩展
import RightToolbar from "@/components/RightToolbar"
import TableContextMenu from "@/components/TableContextMenu"
import DrugCategoryEdit from "@/components/DrugCategoryEdit"
import CodeIcon from "@/components/CodeIcon"
import IframePage from "@/components/IframePage"
// 引入自定义指令
import './directive/index'
import install from './directive/preventReClick'
Vue.use(install)
// 引入扩展-element-ui的 Dialog弹框支持拖动
import './utils/dialog'
// 季度时间组件
import DateQuarter from '@/components/DateQuarter'
// 自定义时间轴组件
import DKBLine from '@/components/DKBLine'
import moment from 'moment'// 导入文件
import { DEEP_CLONE } from '@/utils/constants'
import echarts from "echarts"
import VueUeditorWrap from 'vue-ueditor-wrap'
// import '@/assets/font/font.scss';
// import './mock/index.js'
import VueDragResize from 'vue-drag-resize'

Vue.component('vue-drag-resize', VueDragResize)
// 全局注册组件
Vue.component('vue-ueditor-wrap', VueUeditorWrap)
Vue.component(Table.name, Table)
// 全局方法挂载
Vue.prototype.$echarts = echarts
Vue.prototype.getDicts = getDicts
Vue.prototype.getConfigKey = getConfigKey
Vue.prototype.parseTime = parseTime
Vue.prototype.resetForm = resetForm
Vue.prototype.clearFormValidate = clearFormValidate
Vue.prototype.addDateRange = addDateRange
Vue.prototype.selectDictLabel = selectDictLabel
Vue.prototype.selectDictLabels = selectDictLabels
Vue.prototype.download = download
Vue.prototype.handleTree = handleTree
Vue.prototype.$moment = moment
Vue.prototype.$deepClone = DEEP_CLONE
Vue.prototype.$eventBus = new Vue()
Vue.prototype.msgSuccess = function (msg) {
  if(!msg) msg='操作成功'
  this.$message({ showClose: true, message: msg, type: "success" });
}

Vue.prototype.msgError = function (msg) {
  this.$message({ showClose: true, message: msg, type: "error" });
}

Vue.prototype.msgInfo = function (msg) {
  this.$message.info(msg);
}

//设置当前激活的元素失去焦点
Vue.prototype.setBlur = function () {
  document.activeElement.blur()
}


// 全局组件挂载
Vue.component('Pagination', Pagination)
Vue.component('RightToolbar', RightToolbar)
Vue.component('TableContextMenu', TableContextMenu)
Vue.component('DrugCategoryEdit', DrugCategoryEdit)
Vue.component('DateQuarter', DateQuarter)
Vue.component('DKBLine', DKBLine)
Vue.component('CodeIcon', CodeIcon)
Vue.component('IframePage', IframePage)
Vue.use(columnSettings)
Vue.use(edtColumnSettings)
Vue.use(basePrototype)
Vue.use(permission)

/**
 * If you don't want to use mock-server
 * you want to use MockJs for mock api
 * you can execute: mockXHR()
 *
 * Currently MockJs will be used in the production environment,
 * please remove it before going online! ! !
 */

Vue.use(Element, {
  size: Cookies.get('size') || 'medium' // set element-ui default size
})

Vue.config.productionTip = false

new Vue({
  el: '#app',
  router,
  store,
  render: h => h(App)
})


//多个页面传参使用
// window.acrossUtil= {
//   cache:{},
//   getVal:function (key){
//     return cache[key]
//   },
//   setVal:function (key,val){
//     cache[key]=val
//   }
// }
