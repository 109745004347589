var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticStyle: { width: "100%", height: "100%" } },
    [
      _c(
        "keep-alive",
        [!_vm.$route.meta.url ? _c("router-view", { key: _vm.key }) : _vm._e()],
        1
      ),
      _c("iframe-toggle", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.$route.meta.url,
            expression: "$route.meta.url",
          },
        ],
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }