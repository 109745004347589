<!--自定义图标组件-->
<template>
  <span>
    <!-- base64生成图标-->
    <svg style="width: 14px;height: 14px;vertical-align: middle;margin-right: 5px;"
         v-if="iconType=='base64'"
         v-bind:style="{ width: size + 'px',height: size + 'px' }"
    >
      <image style="width: 100%;height: 100%" :xlink:href="iconCode"></image>
    </svg>
<!--    使用css生成图标-->
    <span v-if="iconType=='css' && cssIcon.codeType" class="code-icon" >
      <el-tag effect="plain" size="small"
              v-bind:style="{ color:cssIcon.color }"
               :type="cssIcon.type">{{ cssIcon.text }}</el-tag>
    </span>
  </span>

</template>

<script>

export default {
  name: 'ElIcon',
  props: {
    iconCode: {
      type: String,
      default: ''
    },
    size: {
      type: Number,
      default: 14
    }
  },
  data() {
    return {}
  },
  computed: {
    iconType() {
      if (this.iconCode.indexOf('data:image/svg+xml;base64') >= 0) {
        return 'base64'
      } else {
        return 'css'
      }
    },
    cssIcon() {
      try {
        return JSON.parse(this.iconCode)
      } catch (err) {
        return {
          text: ''
        }
      }

    }

  }
}
</script>

<style lang="scss" scoped>

.code-icon {
  height: 24px;
  opacity: 1;
  font-size: 12px;
  font-family: Microsoft YaHei-Regular, Microsoft YaHei;
  font-weight: 400;

}

.code-icon {
  ::v-deep .el-tag--plain {
    border-radius: 20px;
    border: 1px solid;
  }
}
</style>
