
const state = {
  title:'测试',
  concepts:{}
}


const mutations = {
  CHANGE_SETTING: (state, { key, value }) => {
    if (state.hasOwnProperty(key)) {
      state[key] = value
    }
  },
  ADD_CONCEPT: (state, { key, value,includeSon }) => {
    let realKey=key+'#####'+includeSon
    state.concepts[realKey]=value
  },
}

const actions = {
  changeSetting({ commit }, data) {
    commit('CHANGE_SETTING', data)
  },
  addConcept({ commit },data) {
    commit('ADD_CONCEPT',data)
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
